import { Navigate, useRoutes } from "react-router-dom";
import { DashboardLayout } from "./layouts/dashboard/DashboardLayout";
import EmptyLayout from "./layouts/EmptyLayout";
import NotFoundPage from "./pages/404/NotFound";
import BioPage from "./pages/bio/Bio";
import BlogPage from "./pages/blog/Blog";
import BlogPost from "./pages/blog/BlogPost";
import BooksPage from "./pages/books/Books";
import DashboardPage from "./pages/dashboard/Dashboard";
import HomePage from "./pages/home/Home";
import OpenAiApiClient from "./api/OpenAiApiClient";
import { useMemo, useState } from "react";
import {
  getOpenAiApiKeyLocalStorage,
  setOpenAiApiKeyLocalStorage,
} from "./api/LocalEnvironmentClient";
import DailyUpdatePage from "./pages/daily/DailyUpdatePage";

export default function Router() {
  const [openAiApiKey, setOpenAiApiKey] = useState<string>("");

  const openAiApi = useMemo(
    () => (openAiApiKey ? new OpenAiApiClient(openAiApiKey) : undefined),
    [openAiApiKey]
  );

  const getOpenAiApiKeyFromEnv = () => {
    if (!openAiApiKey) {
      const key: string = getOpenAiApiKeyLocalStorage();
      if (key && key.trim()) {
        if (
          process.env.REACT_APP_OPENAI_API_KEY_PASS === key &&
          process.env.REACT_APP_OPENAI_API_KEY
        ) {
          setOpenAiApiKey(process.env.REACT_APP_OPENAI_API_KEY);
        } else {
          setOpenAiApiKey(key);
        }
      }
    }
  };

  const resetOpenAiApiKey = () => {
    setOpenAiApiKey("");
    setOpenAiApiKeyLocalStorage("");
  };

  getOpenAiApiKeyFromEnv();

  return useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        {
          path: "",
          element: (
            <DashboardPage
              openAiApi={openAiApi}
              openAiApiFunctions={{
                openAiApiKey,
                setOpenAiApiKey,
                resetOpenAiApiKey,
              }}
            />
          ),
        },
      ],
    },
    {
      path: "/recipes",
      element: <DashboardLayout />,
      children: [
        {
          path: "",
          element: (
            <DashboardPage
              openAiApi={openAiApi}
              openAiApiFunctions={{
                openAiApiKey,
                setOpenAiApiKey,
                resetOpenAiApiKey,
              }}
              widgetType={"recipes"}
            />
          ),
        },
      ],
    },
    {
      path: "/generate-image",
      element: <DashboardLayout />,
      children: [
        {
          path: "",
          element: (
            <DashboardPage
              openAiApi={openAiApi}
              openAiApiFunctions={{
                openAiApiKey,
                setOpenAiApiKey,
                resetOpenAiApiKey,
              }}
              widgetType={"generate-image"}
            />
          ),
        },
      ],
    },
    {
      path: "/itinerary",
      element: <DashboardLayout />,
      children: [
        {
          path: "",
          element: (
            <DashboardPage
              openAiApi={openAiApi}
              openAiApiFunctions={{
                openAiApiKey,
                setOpenAiApiKey,
                resetOpenAiApiKey,
              }}
              widgetType={"itinerary"}
            />
          ),
        },
      ],
    },
    {
      path: "/books",
      element: <DashboardLayout />,
      children: [{ path: "", element: <BooksPage /> }],
    },
    {
      path: "/daily",
      element: <DashboardLayout />,
      children: [{ path: "", element: <DailyUpdatePage /> }],
    },
    {
      path: "/bio",
      element: <DashboardLayout />,
      children: [{ path: "", element: <BioPage /> }],
    },
    {
      path: "/blog",
      element: <DashboardLayout />,
      children: [{ path: "", element: <BlogPage /> }],
    },
    {
      path: "/blog/:id",
      element: <DashboardLayout />,
      children: [{ path: "", element: <BlogPost /> }],
    },
    {
      path: "/",
      element: <DashboardLayout />,
      children: [{ path: "/", element: <HomePage /> }],
    },
    /* Finally, catch all unmatched routes */
    {
      path: "/404",
      element: <EmptyLayout />,
      children: [{ path: "", element: <NotFoundPage /> }],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);
}
