import { Box, Button, ListItem } from "@mui/material";
import { ReactNode } from "react";
import { NavLink, useLocation } from "react-router-dom";

export const NavItem = ({
  href,
  icon,
  title,
}: {
  href: string;
  icon: ReactNode;
  title: string;
}) => {
  const { pathname } = useLocation();
  const isActive =
    href === "/" ? pathname === "/" : href ? pathname.includes(href) : false;

  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        mb: 0.5,
        py: 0,
        px: 2,
        "> a": {
          textDecoration: "none",
          width: "100%",
        },
      }}
    >
      <NavLink to={href}>
        <Button
          component="span"
          startIcon={icon}
          disableRipple
          sx={{
            backgroundColor: isActive ? "rgba(255,255,255,0.08)" : "grey.900",
            borderRadius: 1,
            color: isActive ? "secondary.main" : "grey.300",
            fontWeight: "fontWeightBold",
            justifyContent: "flex-start",
            px: 3,
            textAlign: "left",
            textTransform: "none",
            width: "100% !important",
            textDecoration: "none",
            "& .MuiButton-startIcon": {
              color: isActive ? "secondary.main" : "grey.400",
            },
            "&:hover": {
              backgroundColor: "rgba(255,255,255, 0.08)",
            },
          }}
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
        </Button>
      </NavLink>
    </ListItem>
  );
};
