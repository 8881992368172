import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ActivitiesWidget from "../../components/widget/ActivitiesWidget";
import OpenAiApiClient, { OpenAiApiFunctions } from "../../api/OpenAiApiClient";
import OpenAiWidget from "../../components/widget/OpenAiWidget";
import RecipeWidget from "../../components/widget/RecipeWidget";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ImageGeneratorWidget from "../../components/widget/ImageGeneratorWidget";

export default function DashboardPage({
  openAiApi,
  openAiApiFunctions,
  widgetType,
}: {
  openAiApi: OpenAiApiClient | undefined;
  openAiApiFunctions: OpenAiApiFunctions;
  widgetType?: String;
}) {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid
        container
        spacing={3}
        rowSpacing={3}
        justifyContent="center"
        alignItems="center"
      >
        {widgetType === "recipes" ? (
          <RecipeWidgetContainer
            openAiApi={openAiApi}
            openAiApiFunctions={openAiApiFunctions}
          />
        ) : widgetType === "itinerary" ? (
          <ItineraryWidgetContainer
            openAiApi={openAiApi}
            openAiApiFunctions={openAiApiFunctions}
          />
        ) : widgetType === "generate-image" ? (
          <ImageGeneratorWidgetContainer
            openAiApi={openAiApi}
            openAiApiFunctions={openAiApiFunctions}
          />
        ) : (
          <>
            <DashboardHeader />
            <ImageGeneratorWidgetContainer
              openAiApi={openAiApi}
              openAiApiFunctions={openAiApiFunctions}
            />
            <RecipeWidgetContainer
              openAiApi={openAiApi}
              openAiApiFunctions={openAiApiFunctions}
            />
            <ItineraryWidgetContainer
              openAiApi={openAiApi}
              openAiApiFunctions={openAiApiFunctions}
            />
          </>
        )}
      </Grid>
    </Container>
  );
}

function DashboardHeader() {
  return (
    <Grid item xs={12} md={8}>
      <Paper
        sx={{
          p: 4,
          alignItems: "left",
        }}
      >
        <Typography variant="h3">Projects</Typography>

        <Typography variant="body1">
          A collection of recent experiments and widgets. Follow along with what
          I'm building here. Some of these widgets require an{" "}
          <Link target="_blank" href="https://openai.com/blog/openai-api">
            OpenAI API key
          </Link>{" "}
          to operate.
        </Typography>

        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <Link href="generate-image" color="primary">
                <ListItemText primary="Image Generator" />
              </Link>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link href="recipes" color="primary">
                <ListItemText primary="What To Cook Today?" />
              </Link>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link href="itinerary" color="primary">
                <ListItemText primary="What To Do Today?" />
              </Link>
            </ListItemButton>
          </ListItem>
        </List>
      </Paper>
    </Grid>
  );
}

function RecipeWidgetContainer({
  openAiApi,
  openAiApiFunctions,
}: {
  openAiApi: OpenAiApiClient | undefined;
  openAiApiFunctions: OpenAiApiFunctions;
}) {
  return (
    <Grid item xs={12} md={8}>
      <OpenAiWidget
        title={"What To Cook Today?"}
        description={
          "This widget generates a number of recipe ideas based on your desired cuisine type and serving size. Try advanced search for something specific."
        }
        widget={<RecipeWidget openAiApi={openAiApi} />}
        openAiApi={openAiApi}
        openAiApiFunctions={openAiApiFunctions}
      />
    </Grid>
  );
}

function ItineraryWidgetContainer({
  openAiApi,
  openAiApiFunctions,
}: {
  openAiApi: OpenAiApiClient | undefined;
  openAiApiFunctions: OpenAiApiFunctions;
}) {
  return (
    <Grid item xs={12} md={8}>
      <OpenAiWidget
        title={"What To Do Today?"}
        description={
          "This widget takes a few simple inputs and generates a one day itinerary based on your desired location and preferences."
        }
        widget={<ActivitiesWidget openAiApi={openAiApi} />}
        openAiApi={openAiApi}
        openAiApiFunctions={openAiApiFunctions}
      />
    </Grid>
  );
}

function ImageGeneratorWidgetContainer({
  openAiApi,
  openAiApiFunctions,
}: {
  openAiApi: OpenAiApiClient | undefined;
  openAiApiFunctions: OpenAiApiFunctions;
}) {
  return (
    <Grid item xs={12} md={8}>
      <OpenAiWidget
        title={"Image Generator"}
        description={"Generate images using DALL-E."}
        widget={<ImageGeneratorWidget openAiApi={openAiApi} />}
        openAiApi={openAiApi}
        openAiApiFunctions={openAiApiFunctions}
      />
    </Grid>
  );
}
