import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import { BlogInfo, BlogCardContent } from "./Blog";
import Paper from "@mui/material/Paper";

export default function BlogPost() {
  const [post, setPost] = React.useState<BlogInfo>();
  const [content, setContent] = React.useState("");
  const { id } = useParams();

  React.useEffect(() => {
    fetch("/data/blog.json")
      .then((res) => res.json())
      .then((data: BlogInfo[]) => {
        var isSet = false;
        data.forEach((blogInfo) => {
          if (blogInfo.id === id) {
            setPost(blogInfo);
            isSet = true;
          }
        });
        if (!isSet) window.location.href = "/404";
      });
  }, [id]);

  React.useEffect(() => {
    post &&
      fetch(`/data/${post.contentSource}`)
        .then((res) => res.text())
        .then((text) => {
          if (text) setContent(text);
        });
  }, [post]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid
        container
        spacing={3}
        rowSpacing={3}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={10}>
          <Paper
            sx={{
              p: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            {post && <BlogCardContent blog={post} content={content} />}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
