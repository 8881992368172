import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Timeline from "./Timeline";
import { Avatar, useTheme } from "@mui/material";
import "./Bio.css";
import WorkExperience from "./WorkExperience";
import AgeProgress from "../../components/AgeProgress";
import { S3_PHOTO_BUCKET_URL } from "../../theme/ConstantsProvider";

export default function BioPage() {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid
        container
        spacing={3}
        rowSpacing={3}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={8}>
          <BioCard />
        </Grid>
        <Grid item xs={12} md={8}>
          <WorkExperienceCard />
        </Grid>
      </Grid>
    </Container>
  );
}

function BioCard() {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        p: 4,
        flexDirection: "column",
      }}
    >
      <Avatar
        src={S3_PHOTO_BUCKET_URL + "canyon.png"}
        alt={"Me"}
        variant="rounded"
        className="bio-image"
      />

      <Typography variant="h3">About Me</Typography>

      <Typography variant="body1">
        This page is a quick rundown about myself and what I've been working on.
        Welcome, thanks for stopping by. Say hi on{" "}
        <Link href="https://www.instagram.com/blanketrock/" target="_blank">
          Instagram
        </Link>
        ,{" "}
        <Link href="https://www.twitter.com/blanketrock/" target="_blank">
          Twitter
        </Link>
        , or{" "}
        <Link href="mailto:blanketrock@mail.com" target="_blank">
          {" "}
          email
        </Link>
        .
      </Typography>

      {/* TODO: Add a tidbit about what I'm up to before posting this (and update time/date below 
        <br/>
        <Typography variant="body1">Lately, I've been interested in Generative AI (isn't everyone?) </Typography>
      */}

      <Typography
        variant="body2"
        sx={{ mt: 1, color: theme.palette.grey["500"] }}
      >
        Last updated <span id="last-updated-date">October 2024</span> from{" "}
        <span id="last-updated-location">San Diego, California.</span>
      </Typography>
      <br />

      <Typography variant="h4" sx={{ mb: 1 }}>
        Timeline <AgeProgress />
      </Typography>

      <Timeline />
    </Paper>
  );
}

function WorkExperienceCard() {
  return (
    <Paper
      sx={{
        p: 4,
        flexDirection: "column",
      }}
    >
      <Typography variant="h4">Work Experience</Typography>
      <br />

      {/* TODO: Add quick blurb and link to download resume. Maybe LinkedIn eventually? */}

      <WorkExperience />
    </Paper>
  );
}
