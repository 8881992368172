import React from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

interface TimelineData {
  title: string;
  yearRange: string;
  description: string;
  isOpen?: boolean;
}

const timelineData = [
  {
    title: "Childhood",
    yearRange: "1995 - 2012",
    description:
      "I grew up in Melbourne Beach, Florida, in a close-knit family with my younger brother and twin sisters. Our childhood was filled with days spent at the beach, where I developed a love for surfing and other outdoor activities. In addition to my passion for sports, I played the guitar and did well in school. I worked a variety of odd jobs such as refereeing soccer games, tutoring, and lifeguarding. These experiences taught me valuable skills such as teamwork, communication, and leadership.",
  },
  {
    title: "College",
    yearRange: "2013 - 2016",
    description:
      "In 2013, I moved to Gainesville to pursue a bachelor's degree in Computer Engineering at the University of Florida. These four years were a blast. I continued to work odd jobs, including making T-shirts, driving a campus taxi, and guarding the doors as a bouncer at an infamous local bar, The Swamp. Graduation came and I was tossed into the real world.",
  },
  {
    title: "Fresh Grad",
    yearRange: "2017 - 2020",
    description:
      "Before moving to South Florida and starting my first job, I spent a few months backpacking around Indonesia and Australia. I returned full of energy and learned a ton over the next three years coding at American Express - working on API development, big data batch processing, and developing machine learning models. I caught the travel bug and traveled as much as possible, visiting places such as India, South America, the Caribbean, and around Europe.",
  },
  {
    title: "Present",
    yearRange: "2021 - Present",
    description:
      "In 2021, I packed my things and drove across the country to live in San Diego. From 9am to 5pm you can find me writing code for Amazon and otherwise spending as much time as possible outdoors - surfing, hiking, and enjoying the beauty of southern California.",
  },
];

export default function Timeline() {
  return (
    <List
      sx={{ width: "100%", bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {timelineData.map((data) => (
        <TimelineSection timelineData={data} key={data.title} />
      ))}
    </List>
  );
}

function TimelineSection({ timelineData }: { timelineData: TimelineData }) {
  const [open, setOpen] = React.useState(timelineData.isOpen);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div>
      <ListItemButton onClick={handleClick} sx={{ boxShadow: 1 }}>
        <ListItemText
          primary={timelineData.yearRange}
          secondary={timelineData.title}
        />

        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Paper
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <Typography variant="body2">{timelineData.description}</Typography>
        </Paper>
      </Collapse>
    </div>
  );
}
