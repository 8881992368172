import React from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import Collapse from "@mui/material/Collapse";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

interface WorkExperienceData {
  title: string;
  job: string;
  date: string;
  image: string;
  content: string;
  technologies: Array<string>;
}

const workExperienceData = [
  {
    title: "Amazon",
    job: "Software Engineer",
    date: "2020 - Present",
    image:
      "https://media.licdn.com/dms/image/C560BAQHTvZwCx4p2Qg/company-logo_200_200/0/1612205615891?e=2147483647&v=beta&t=DbdWu153GcoESwso9HD1UQ8Y4f-3b5XP5h7SiQSlQcE",
    content:
      "Coding at Amazon has been a goal of mine since I started looking at job opportunities in college. I was initially inspired by the vision of solving hard tehcnical problems like drone delivery, and the more I read into the guiding principles of the company, the more I realized that the values of customer obsession and ownership resonated with me. I work with an amazing team in the Payment Risk Engineering organization where it is our job to protect Amazon payments from bad actors and prevent bad debt. I am challenged and continue to learn every day.",
    technologies: ["AWS", "Java", "Python"],
  },
  {
    title: "American Express",
    job: "Software Engineer",
    date: "2017 - 2020",
    image:
      "https://media.licdn.com/dms/image/C4D0BAQGRhsociEn4gQ/company-logo_200_200/0/1523269243842?e=2147483647&v=beta&t=Uq6sFxarYcmx_bj70YMBqytPr5wBpnNrH8e21StzsYg",
    content:
      "My first job out of college was as a programmer at American Express. I worked on a number of exciting projects, but the one I am most proud of was a set of machine learning models to improve the rule-based matching process for customer and business accounts. I also built one of their technical recruiting applications, which was used at events across the country like Grace Hopper to gather and process candidate information. My few years at American Express were an incredible learning opportunity and I grew exponentially as an engineer by working on systems at scale.",
    technologies: ["Spark", "VertX", "Tensorflow", "ReactJS"],
  },
  {
    title: "Sprightful",
    job: "Developer",
    date: "2017",
    image:
      "https://static.wixstatic.com/media/836835_46fc56fa83a941848463704287908785~mv2.png/v1/fill/w_49,h_49,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/0fdfc03d-c5e2-4846-9a2c-81ab5253b4fe_edi.png",
    content:
      "Sprightful is a company that has installed over 10,000 solar panels to homes across South Florida. The owner of the business approached a friend and me with a need for a customer dashboard that would display their energy savings from these panels, manage billing, and control their energy usage. We built a full-stack web app solution using Django and integrated with APIs like Ecobee, Neurio, and Stripe for the data and payments. We programmed local on-peak and off-peak energy calculations into a useful billing tool.",
    technologies: ["Django", "JavaScript", "Postgres"],
  },
  {
    title: "Discount Stash",
    job: "Creator",
    date: "2016",
    image: "/static/images/discountStash.png",
    content:
      "Discount Stash was a mobile application I built for a couple of Melbourne-based surf shop owners looking to start a local online coupon business. I created the frontend using Ionic Framework, a toolkit for writing native iOS and Android applications using AngularJS, and hooked this up to a Firebase backend. The app consisted of a simple UI for users to sort and search through deals at local businesses and an admin dashboard for the business owners to manage their offerings. This ended up being a success and was used by a few thousand people around Melbourne until the owners decided to pivot the business towards Facebook advertising.",
    technologies: ["Ionic Framework", "AngularJS", "Firebase", "Node"],
  },
];

export default function WorkExperience() {
  return (
    <div>
      {workExperienceData.map((data) => (
        <WorkExperienceSection workExperienceData={data} key={data.title} />
      ))}
    </div>
  );
}

function WorkExperienceSection({
  workExperienceData,
}: {
  workExperienceData: WorkExperienceData;
}) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div>
      <ListItemButton onClick={handleClick} sx={{ boxShadow: 1 }}>
        <ListItemAvatar>
          <Avatar
            alt={workExperienceData.title}
            src={workExperienceData.image}
          />
        </ListItemAvatar>
        <ListItemText
          primary={workExperienceData.title}
          secondary={workExperienceData.job + " | " + workExperienceData.date}
        />

        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Paper
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <Typography variant="body2">{workExperienceData.content}</Typography>

          {workExperienceData.technologies && (
            <Stack
              direction="row"
              spacing={1}
              marginTop="20px"
              justifyContent="center"
            >
              {workExperienceData.technologies.map((tech) => (
                <Chip key={tech} label={tech} variant="outlined" />
              ))}
            </Stack>
          )}
        </Paper>
      </Collapse>
    </div>
  );
}
