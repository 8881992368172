import { Box, Theme } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import HomeIcon from "@mui/icons-material/Home";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
// import CreateIcon from "@mui/icons-material/Create";
import BarChartIcon from "@mui/icons-material/BarChart";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import { NavItem } from "./NavItem";

const navItems = [
  {
    href: "/",
    icon: <HomeIcon fontSize="small" />,
    title: "Home",
  },
  {
    href: "/daily",
    icon: <NewspaperIcon fontSize="small" />,
    title: "Daily Updates",
  },
  {
    href: "/dashboard",
    icon: <BarChartIcon fontSize="small" />,
    title: "Projects",
  },
  {
    href: "/books",
    icon: <AutoStoriesIcon fontSize="small" />,
    title: "Reading",
  },
  // {
  //   href: "/blog",
  //   icon: <CreateIcon fontSize="small" />,
  //   title: "Writing",
  // },
  // {
  //   href: "/photography",
  //   icon: <CameraAltIcon fontSize="small" />,
  //   title: "Photography",
  // },
  {
    href: "/bio",
    icon: <AccountCircleIcon fontSize="small" />,
    title: "Bio",
  },
];

export const DashboardSidebar = ({
  isOpen,
  setIsSidebarOpen,
}: {
  isOpen: boolean;
  setIsSidebarOpen: Function;
}) => {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"), {
    defaultMatches: true,
    noSsr: false,
  });

  const content = (
    <>
      <Box
        sx={{ flexGrow: 1, marginTop: "30px" }}
        onClick={() => setIsSidebarOpen(false)}
      >
        {navItems.map((item) => (
          <NavItem
            key={item.title}
            icon={item.icon}
            href={item.href}
            title={item.title}
          />
        ))}
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open={true}
        PaperProps={{
          sx: {
            backgroundColor: "grey.900",
            color: "#FFFFFF",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={() => setIsSidebarOpen(false)}
      open={isOpen}
      PaperProps={{
        sx: {
          backgroundColor: "grey.900",
          color: "#FFFFFF",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};
