import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import OpenAiApiClient from "../../api/OpenAiApiClient";

const SYSTEM_PROMPT = `You are an expert chef and helpful recipe generator. You speak only in JSON.`;

const dishDescriptions: string[] = [
  "Prepare to be amazed by this culinary masterpiece that will tantalize your taste buds and leave you craving for more.",
  "Indulge in a symphony of flavors and textures that will transport you to food heaven with every mouthful.",
  "Get ready to embark on a gastronomic adventure that will redefine your perception of deliciousness.",
  "Savor the exquisite blend of ingredients and expert craftsmanship, resulting in a dish that will leave you speechless.",
  "This culinary creation is a delightful concoction of flavors that will dance on your palate and leave you wanting an encore.",
  "Experience a burst of savory and sweet notes in perfect harmony, making each bite a delightful surprise for your senses.",
  "Prepare for an explosion of taste that will make your heart skip a beat and have you declaring this dish a true work of art.",
  "Get ready to be transported to a world of culinary bliss, where each bite will take you on an unforgettable flavor journey.",
  "Indulge in this divine creation that will make you close your eyes in blissful delight, savoring every moment of pure culinary perfection.",
  "Embark on a culinary adventure where innovation meets tradition, resulting in a dish that will leave you breathless and craving for more.",
];

const mealTypes = [
  { id: "breakfast", name: "Breakfast" },
  { id: "lunch", name: "Lunch" },
  { id: "dinner", name: "Dinner" },
  { id: "dessert", name: "Dessert" },
];

const specialPreferenceTypes = [
  { id: "gluten free", name: "Gluten Free" },
  { id: "dairy free", name: "Dairy Free" },
  { id: "vegetarian", name: "Vegetarian" },
  { id: "vegan", name: "Vegan" },
  { id: "cheap", name: "Cheap" },
  { id: "healthy", name: "Healthy" },
  { id: "seasonal", name: "Seasonal" },
  { id: "30 minute or less", name: "30 Minute Or Less" },
];

const topIngredients: string[] = [
  "Alligator",
  "Allspice",
  "Almond Milk",
  "Almonds",
  "Apples",
  "Artichokes",
  "Asparagus",
  "Avocado",
  "Baking Powder",
  "Baking Soda",
  "Bananas",
  "Basil",
  "Bay Leaves",
  "Beef",
  "Beer",
  "Beets",
  "Bell Peppers",
  "Bison",
  "Black Pepper",
  "Blueberries",
  "Bok Choy",
  "Bread",
  "Broccoli",
  "Brussels Sprouts",
  "Butter",
  "Butternut Squash",
  "Cabbage",
  "Camel",
  "Caraway Seeds",
  "Cardamom",
  "Carrots",
  "Cashews",
  "Cauliflower",
  "Cayenne Pepper",
  "Celery Seeds",
  "Celery",
  "Chia Seeds",
  "Chicken",
  "Chili Powder",
  "Chocolate",
  "Cilantro",
  "Cinnamon",
  "Cloves",
  "Cocoa Powder",
  "Coconut Cream",
  "Coconut Milk",
  "Coconut",
  "Coffee",
  "Coriander",
  "Corn",
  "Cucumbers",
  "Cumin",
  "Dill",
  "Duck",
  "Eggplant",
  "Eggs",
  "Elk",
  "Emu",
  "Fennel Seeds",
  "Fennel",
  "Fenugreek",
  "Flaxseed",
  "Flour",
  "Garlic",
  "Gin",
  "Ginger",
  "Goat",
  "Goose",
  "Grapes",
  "Green Beans",
  "Grouse",
  "Guinea Fowl",
  "Hoison",
  "Honey",
  "Horse",
  "Kale",
  "Kangaroo",
  "Ketchup",
  "Lamb",
  "Leeks",
  "Lemon",
  "Lettuce",
  "Lime",
  "Mango",
  "Maple Syrup",
  "Mayonnaise",
  "Milk",
  "Mint",
  "Mushrooms",
  "Mustard Seeds",
  "Mustard",
  "Nutmeg",
  "Oats",
  "Okra",
  "Olive Oil",
  "Onion",
  "Orange",
  "Oregano",
  "Ostrich",
  "Paprika",
  "Parmesan Cheese",
  "Parsley",
  "Parsnips",
  "Pasta",
  "Peaches",
  "Peanut Butter",
  "Peas",
  "Pecans",
  "Pepper",
  "Pheasant",
  "Pineapple",
  "Pork",
  "Potatoes",
  "Pretzels",
  "Pumpkin",
  "Quail",
  "Quinoa",
  "Rabbit",
  "Radicchio",
  "Radishes",
  "Raspberries",
  "Red Wine",
  "Rice",
  "Rosemary",
  "Rum",
  "Saffron",
  "Salt",
  "Sesame Oil",
  "Shallots",
  "Shrimp",
  "Sour Cream",
  "Soy Milk",
  "Soy Sauce",
  "Spinach",
  "Spring Onions",
  "Star Anise",
  "Strawberries",
  "Sugar",
  "Sunflower Seeds",
  "Sweet Potatoes",
  "Swiss Chard",
  "Tea",
  "Thyme",
  "Tomatoes",
  "Turkey",
  "Turmeric",
  "Turnips",
  "Vanilla Beans",
  "Vanilla Extract",
  "Veal",
  "Venison",
  "Vinegar",
  "Vodka",
  "Walnuts",
  "Watercress",
  "Watermelon",
  "Whipped Cream",
  "Whiskey",
  "White Pepper",
  "White Wine",
  "Wild Boar",
  "Worcestershire Sauce",
  "Yogurt",
  "Zucchini",
];

export default function RecipeWidget({
  openAiApi,
}: {
  openAiApi: OpenAiApiClient | undefined;
}) {
  const [dishes, setDishes] = useState<DishList>();
  const [servingSizePreference, setServingSizePreference] =
    useState<string>("4");

  return (
    <div>
      {dishes ? (
        <DishListForm
          openAiApi={openAiApi!}
          dishes={dishes}
          setDishes={setDishes}
          servingSizePreference={servingSizePreference}
        />
      ) : (
        <RecipeForm
          openAiApi={openAiApi!}
          setDishes={setDishes}
          servingSizePreference={servingSizePreference}
          setServingSizePreference={setServingSizePreference}
        />
      )}
    </div>
  );
}

interface Ingredient {
  name: string;
  quantity: string;
  unit: string;
}

interface Dish {
  name: string;
  cooking_time: string;
  description: string;
  ingredients?: Ingredient[];
  steps?: string[];
}

interface Recipe {
  recipe_name: string;
  serving_size?: string;
  cooking_time?: string;
  ingredients: Ingredient[];
  steps: string[];
}

interface DishList {
  dishes: Dish[];
}

function DishListForm({
  openAiApi,
  dishes,
  setDishes,
  servingSizePreference,
}: {
  openAiApi: OpenAiApiClient;
  dishes: DishList;
  setDishes: Function;
  servingSizePreference: string;
}) {
  const [copiedDish, setCopiedDish] = useState("");
  const [loadingRecipeDish, setLoadingRecipeDish] = useState("");
  const [isErrorUpdate, setIsErrorUpdate] = useState(false);

  const onClear = () => {
    setDishes(undefined);
  };

  const generateRecipePrompt = (dishName: string) => {
    return `Return a json recipe including an ingredient list for ${dishName} with a serving size of ${servingSizePreference}. Make the recipe interesting if possible, be creative but realistic. Include no additional text around the JSON. Example response: {"recipe_name": "Recipe Name", "serving_size": "4", "cooking_time": "40 minutes", "ingredients": [{"name": "ingredient", "quantity": "4", "unit": "pieces"}, ... ], "steps": ["Preheat ...", ...] }`;
  };

  const updateDishesWithRecipe = (recipe: Recipe, dishName: string) => {
    const updatedDishes = [...dishes.dishes];
    const dishIndex = updatedDishes.findIndex((dish) => dishName === dish.name);
    updatedDishes[dishIndex].ingredients = recipe.ingredients;
    updatedDishes[dishIndex].steps = recipe.steps;
    if (recipe.cooking_time)
      updatedDishes[dishIndex].cooking_time = recipe.cooking_time;
    setDishes({ ...dishes, dishes: updatedDishes });
  };

  const getRecipe = (dishName: string) => {
    const user_prompt = generateRecipePrompt(dishName);
    setLoadingRecipeDish(dishName);

    const response = openAiApi.createChatCompletion(SYSTEM_PROMPT, user_prompt); // openAiApi.mockRecipeCompletion();
    // console.log("Prompt: " + user_prompt);

    response.then((res) => {
      if (res) {
        try {
          // console.log("Response: " + res);
          const recipe: Recipe = JSON.parse(res);
          updateDishesWithRecipe(recipe, dishName);
          setLoadingRecipeDish("");
        } catch (error) {
          console.log("Invalid response from ChatGPT");
          setIsErrorUpdate(true);
        }
      } else {
        console.log("Empty response from ChatGPT");
        setIsErrorUpdate(true);
      }
    });
  };

  // TODO: Make this "pretty" instead of JSON. Do the same for itinerary
  const onShare = (dish: Dish) => {
    navigator.clipboard.writeText(JSON.stringify(dish, null, 2)).then(() => {
      setCopiedDish(dish.name);
      setTimeout(() => setCopiedDish(""), 4000);
    });
  };

  useEffect(() => {
    if (isErrorUpdate)
      setTimeout(() => {
        setIsErrorUpdate(false);
        setLoadingRecipeDish("");
      }, 4000);
  }, [isErrorUpdate]);

  return (
    <Grid container spacing={2}>
      {dishes.dishes.map((dish) => {
        return (
          <Grid item xs={12} key={dish.name}>
            <Typography variant="h5" sx={{ fontSize: "1.2rem" }}>
              {dish.name}
            </Typography>
            <Typography variant="body2">{dish.description}</Typography>
            <Chip
              label={dish.cooking_time}
              variant={"outlined"}
              sx={{ mt: 1, mr: 1 }}
            />
            {!dish.ingredients && !dish.steps ? (
              <Chip
                label={
                  loadingRecipeDish === dish.name ? "Loading..." : "Get Recipe"
                }
                variant={"outlined"}
                sx={{ mt: 1, mr: 1 }}
                color={"secondary"}
                onClick={() => getRecipe(dish.name)}
              />
            ) : (
              <Chip
                label={"Share Recipe"}
                variant={"outlined"}
                sx={{ mt: 1, mr: 1 }}
                color={"primary"}
                onClick={() => onShare(dish)}
              />
            )}

            {copiedDish === dish.name && (
              <Alert sx={{ mt: 2 }} severity="success">
                Copied recipe to cliboard.
              </Alert>
            )}

            {dish.ingredients && dish.steps && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={5} textAlign={"left"}>
                  <Typography
                    variant="h6"
                    sx={{ mt: 2, mb: 1, fontWeight: "bold" }}
                  >
                    {`Ingredients (${servingSizePreference} Servings)`}
                  </Typography>
                  {/* TODO: Make these checkmarks? */}
                  {dish.ingredients.map((ingredient) => {
                    return (
                      <Typography key={ingredient.name} variant="body2">
                        {` - ${ingredient.quantity} ${ingredient.unit} `}
                        <span
                          style={{ fontWeight: "bold" }}
                        >{`${ingredient.name}`}</span>
                      </Typography>
                    );
                  })}
                </Grid>

                <Grid item xs={12} md={7}>
                  <Typography
                    variant="h6"
                    sx={{ mt: 2, mb: 1, fontWeight: "bold" }}
                  >
                    {`Recipe`}
                  </Typography>
                  {dish.steps.map((step, i) => {
                    return (
                      <Typography key={i} variant="body2">{`${
                        i + 1
                      }. ${step}`}</Typography>
                    );
                  })}
                </Grid>
              </Grid>
            )}
          </Grid>
        );
      })}
      <Grid item xs={12} textAlign={"center"}>
        <Button
          variant="contained"
          color="warning"
          // sx={{ width: "20%" }}
          onClick={onClear}
        >
          <RestartAltIcon />
          &nbsp;{"Reset"}
        </Button>
      </Grid>
    </Grid>
  );
}

function RecipeForm({
  openAiApi,
  setDishes,
  servingSizePreference,
  setServingSizePreference,
}: {
  openAiApi: OpenAiApiClient;
  setDishes: Function;
  servingSizePreference: string;
  setServingSizePreference: Function;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isErrorSubmit, setIsErrorSubmit] = useState<boolean>(false);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const [cuisineTypePreference, setCuisineTypePreference] =
    useState<string>("no preference");
  const [advancedSearchText, setAdvancedSearchText] = useState<string>("");
  const [ingredientPreference, setIngredientPreference] =
    useState<string>("no preference");
  const [mealTypePreference, setMealTypePreference] =
    useState<string>("dinner");
  const [specialPreferences, setSpecialPreferences] = useState<string[]>([]);
  const [selectedIngredients, setSelectedIngredients] = useState<string[]>([]);

  const onSubmitSearchClick = () => {
    setIsLoading(true);

    const user_prompt = generateUserPrompt();
    // console.log("Prompt: " + user_prompt);

    const response = openAiApi.createChatCompletion(SYSTEM_PROMPT, user_prompt); // openAiApi.mockDishesCompletion();
    response.then((res) => {
      if (res) {
        //  console.log("Response: " + res);
        try {
          const dishes: DishList = JSON.parse(res);
          setDishes(dishes);
        } catch (error) {
          console.log("Invalid response from ChatGPT");
          setIsErrorSubmit(true);
        }
      } else {
        console.log("Empty response from ChatGPT");
        setIsErrorSubmit(true);
      }
      setIsLoading(false);
    });
  };

  const getGenericDescription = () => {
    const randomIndex = Math.floor(Math.random() * dishDescriptions.length);
    return dishDescriptions[randomIndex];
  };

  const updateDishesWithRecipe = (recipe: Recipe) => {
    const dish: Dish = {
      name: recipe.recipe_name,
      description: getGenericDescription(),
      ingredients: recipe.ingredients,
      steps: recipe.steps,
      cooking_time: recipe.cooking_time ? recipe.cooking_time : "",
    };
    setDishes({ dishes: [dish] });
  };

  const onSubmitAdvancedSearchClick = () => {
    setIsLoading(true);

    const user_prompt = generateAdvancedRecipePrompt(
      advancedSearchText,
      selectedIngredients
    );
    // console.log("Prompt: " + user_prompt);

    const response = openAiApi.createChatCompletion(SYSTEM_PROMPT, user_prompt); // openAiApi.mockDishesCompletion();
    response.then((res) => {
      if (res) {
        try {
          // console.log("Response: " + res);
          const recipe: Recipe = JSON.parse(res);
          updateDishesWithRecipe(recipe);
        } catch (error) {
          console.log("Invalid response from ChatGPT");
          setIsErrorSubmit(true);
        }
      } else {
        console.log("Empty response from ChatGPT");
        setIsErrorSubmit(true);
      }
      setIsLoading(false);
    });
  };

  const generateAdvancedRecipePrompt = (
    recipeDescription: string,
    selectedIngredients: string[]
  ) => {
    return `Return a json recipe including an ingredient list for ${recipeDescription}, with a serving size of ${servingSizePreference}. ${
      selectedIngredients.length > 0
        ? "Try to include these ingredients if possible:" +
          selectedIngredients.join(" ") +
          ". "
        : ""
    }Include no additional text around the JSON. Example response: {"recipe_name": "Recipe Name", "serving_size": "4", "cooking_time": "40 minutes", "ingredients": [{"name": "ingredient", "quantity": "4", "unit": "pieces"}, ... ], "steps": ["Preheat ...", ...] }`;
  };

  const generateUserPrompt = () => {
    return `Return a list of 7 ${getSelectedNoPreferenceString(
      cuisineTypePreference
    )} ${getSelectedNoPreferenceString(
      ingredientPreference
    )} ${specialPreferences.join(
      ", "
    )} ${mealTypePreference} recipes. Include cooking_time and description. Example response: {"dishes": [{"name": "Sheet Pan Lemon Garlic Chicken", "cooking_time": "40 minutes", "description": "A delicious..."}, ... ] }`;
  };

  const getSelectedNoPreferenceString = (inputString: string) => {
    if (inputString === "no preference") return "";
    else return inputString;
  };

  const handleAdvancedSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAdvancedSearchText(event.target.value);
  };

  const handleSelectedIngredients = (
    _: React.SyntheticEvent,
    values: string[]
  ) => {
    setSelectedIngredients(values);
  };

  useEffect(() => {
    if (isErrorSubmit) setTimeout(() => setIsErrorSubmit(false), 4000);
  }, [isErrorSubmit]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Typography variant="h5">Preferences</Typography>
      </Grid>
      <Grid item xs={4} textAlign={"right"}>
        <Tooltip title="Advanced Search" color="secondary">
          <Switch
            color="secondary"
            checked={isAdvancedSearch}
            onChange={() => setIsAdvancedSearch(!isAdvancedSearch)}
          />
        </Tooltip>
      </Grid>
      {isAdvancedSearch ? (
        <>
          <Grid item xs={6} sm={8}>
            <TextField
              label="Recipe Description"
              placeholder="Thai Chili Sauce, or Filet Mignon with Brussel Sprouts" // TODO: make this fun and random?
              value={advancedSearchText}
              onChange={handleAdvancedSearchTextChange}
              variant="outlined"
              fullWidth
            />
            {/* TODO: Add advanced search */}
          </Grid>

          <Grid item xs={6} sm={4}>
            <ServingSizeSelect
              selectedServingSize={servingSizePreference}
              setSelectedServingSize={setServingSizePreference}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={topIngredients} // TODO: generate a realistic set of options
              freeSolo
              value={selectedIngredients}
              onChange={handleSelectedIngredients}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Ingredients"
                  placeholder={
                    selectedIngredients.length === 0
                      ? "Garlic, Onions, Peanut Butter"
                      : ""
                  }
                />
              )}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={6} sm={8}>
            <MealTypePreferenceSelect
              selectedMealType={mealTypePreference}
              setSelectedMealType={setMealTypePreference}
              setSelectedCuisineType={setCuisineTypePreference}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <ServingSizeSelect
              selectedServingSize={servingSizePreference}
              setSelectedServingSize={setServingSizePreference}
            />
          </Grid>
          <Grid item xs={12}>
            <CuisineTypePreferenceSelect
              selectedCuisineType={cuisineTypePreference}
              setSelectedCuisineType={setCuisineTypePreference}
              mealTypePreference={mealTypePreference}
            />
          </Grid>
          {mealTypePreference !== "dessert" && (
            <Grid item xs={12}>
              <IngredientPreferenceSelect
                selectedIngredient={ingredientPreference}
                setSelectedIngredient={setIngredientPreference}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <SpecialPreferencesSelect
              specialPreferences={specialPreferences}
              setSpecialPreferences={setSpecialPreferences}
              ingredientPreference={ingredientPreference}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} textAlign={"center"}>
        <Button
          variant="contained"
          color="primary"
          sx={{ width: "50%" }}
          disabled={isLoading}
          onClick={
            isAdvancedSearch ? onSubmitAdvancedSearchClick : onSubmitSearchClick
          }
        >
          {isLoading ? "Loading..." : "Submit"}
        </Button>
        {isErrorSubmit && (
          <Alert sx={{ mt: 2 }} severity="error">
            There was an error submitting your search. Check your search & API
            key.
          </Alert>
        )}
      </Grid>
    </Grid>
  );
}

function ServingSizeSelect({
  selectedServingSize,
  setSelectedServingSize,
}: {
  selectedServingSize: string;
  setSelectedServingSize: Function;
}) {
  const handleServingSizeChange = (event: SelectChangeEvent<string>) => {
    setSelectedServingSize(event.target.value);
  };

  const servingSizes: { name: string; id: string }[] = Array.from(
    { length: 10 },
    (_, i) => ({ name: `${i + 1}`, id: `${i + 1}` })
  );

  return (
    <FormControl fullWidth>
      <InputLabel id="serving-size-select-label">Serving Size</InputLabel>
      <Select
        labelId="serving-size-select-label"
        id="serving-size-select"
        value={selectedServingSize}
        label="Serving Size"
        onChange={handleServingSizeChange}
      >
        {servingSizes.map((servingSize) => {
          return (
            <MenuItem key={servingSize.id} value={servingSize.id}>
              {servingSize.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

function SpecialPreferencesSelect({
  specialPreferences,
  setSpecialPreferences,
  ingredientPreference,
}: {
  specialPreferences: string[];
  setSpecialPreferences: Function;
  ingredientPreference: string;
}) {
  const handleSpecialPreferencesChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const newPreferences = [...specialPreferences];

    if (newPreferences.includes(value)) {
      newPreferences.splice(newPreferences.indexOf(value), 1);
    } else {
      newPreferences.push(value);
    }

    setSpecialPreferences(newPreferences);
  };

  const isPreferenceDisabled = (preference: String) => {
    if (
      preference === "vegan" &&
      (ingredientPreference === "beef" ||
        ingredientPreference === "chicken" ||
        ingredientPreference === "fish" ||
        ingredientPreference === "pork" ||
        ingredientPreference === "shrimp" ||
        ingredientPreference === "egg")
    )
      return true;
    else if (
      preference === "vegetarian" &&
      (ingredientPreference === "beef" ||
        ingredientPreference === "chicken" ||
        ingredientPreference === "fish" ||
        ingredientPreference === "pork" ||
        ingredientPreference === "shrimp")
    )
      return true;
    return false;
  };

  return (
    <FormGroup row={true}>
      {specialPreferenceTypes.map((preference) => {
        return (
          <FormControlLabel
            key={preference.id}
            control={
              <Checkbox
                color="warning"
                checked={specialPreferences.includes(preference.id)}
                onChange={handleSpecialPreferencesChange}
                value={preference.id}
              />
            }
            label={preference.name}
            disabled={isPreferenceDisabled(preference.id)}
          />
        );
      })}
    </FormGroup>
  );
}

function CuisineTypePreferenceSelect({
  selectedCuisineType,
  setSelectedCuisineType,
  mealTypePreference,
}: {
  selectedCuisineType: string;
  setSelectedCuisineType: Function;
  mealTypePreference: string;
}) {
  const handleCuisineTypeChange = (event: SelectChangeEvent<string>) => {
    setSelectedCuisineType(event.target.value);
  };

  const cuisineTypes =
    mealTypePreference === "dessert"
      ? [
          { id: "cake", name: "Cake" },
          { id: "brownie", name: "Brownie" },
          { id: "cheesecake", name: "Cheesecake" },
          { id: "cookie", name: "Cookie" },
          { id: "cupcake", name: "Cupcake" },
          { id: "ice cream", name: "Ice Cream" },
          { id: "macaron", name: "Macaron" },
          { id: "no preference", name: "No Preference" },
          { id: "pie", name: "Pie" },
          { id: "pudding", name: "Pudding" },
          { id: "tiramisu", name: "Tiramisu" },
        ]
      : [
          { id: "american", name: "American" },
          { id: "caribbean", name: "Caribbean" },
          { id: "chinese", name: "Chinese" },
          { id: "ethiopian", name: "Ethiopian" },
          { id: "french", name: "French" },
          { id: "indian", name: "Indian" },
          { id: "italian", name: "Italian" },
          { id: "japenese", name: "Japenese" },
          { id: "korean", name: "Korean" },
          { id: "mediterranean", name: "Mediterranean" },
          { id: "mexican", name: "Mexican" },
          { id: "no preference", name: "No Preference" },
          { id: "pizza", name: "Pizza" },
          { id: "salad", name: "Salad" },
          { id: "sandwich", name: "Sandwich" },
          { id: "sushi", name: "Sushi" },
          { id: "thai", name: "Thai" },
          { id: "vietnamese", name: "Vietnamese" },
        ];

  return (
    <FormControl fullWidth>
      <InputLabel id="cuisine-type-select-label">Cuisine Type</InputLabel>
      <Select
        labelId="cuisine-type-select-label"
        id="cuisine-type-select"
        value={selectedCuisineType}
        label="Cuisine Type"
        onChange={handleCuisineTypeChange}
      >
        {cuisineTypes.map((cuisineType) => {
          return (
            <MenuItem key={cuisineType.id} value={cuisineType.id}>
              {cuisineType.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

function IngredientPreferenceSelect({
  selectedIngredient,
  setSelectedIngredient,
}: {
  selectedIngredient: string;
  setSelectedIngredient: Function;
}) {
  const handleIngredientChange = (event: SelectChangeEvent<string>) => {
    setSelectedIngredient(event.target.value);
  };

  const ingredients = [
    { id: "bean", name: "Bean" },
    { id: "beef", name: "Beef" },
    { id: "chicken", name: "Chicken" },
    { id: "egg", name: "Egg" },
    { id: "fish", name: "Fish" },
    { id: "no preference", name: "No Preference" },
    { id: "nut", name: "Nut" },
    { id: "pork", name: "Pork" },
    { id: "shrimp", name: "Shrimp" },
    { id: "tofu", name: "Tofu" },
  ];

  return (
    <FormControl fullWidth>
      <InputLabel id="ingredient-select-label">Ingredients</InputLabel>
      <Select
        labelId="ingredient-select-label"
        id="ingredient-select"
        value={selectedIngredient}
        label="Ingredient Type"
        onChange={handleIngredientChange}
      >
        {ingredients.map((ingredient) => {
          return (
            <MenuItem key={ingredient.id} value={ingredient.id}>
              {ingredient.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

function MealTypePreferenceSelect({
  selectedMealType,
  setSelectedMealType,
  setSelectedCuisineType,
}: {
  selectedMealType: string;
  setSelectedMealType: Function;
  setSelectedCuisineType: Function;
}) {
  const handleSelectedMealTypeChange = (event: SelectChangeEvent<string>) => {
    setSelectedMealType(event.target.value);
    setSelectedCuisineType("no preference");
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="meal-type-label">Meal</InputLabel>
      <Select
        labelId="meal-type-label"
        id="meal-type"
        value={selectedMealType}
        label="Meal Type"
        onChange={handleSelectedMealTypeChange}
      >
        {mealTypes.map((mealType) => {
          return (
            <MenuItem key={mealType.id} value={mealType.id}>
              {mealType.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
