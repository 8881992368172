// import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";

export default function AgeProgress() {
  const [age, setAge] = useState(getAge());

  useEffect(() => {
    const interval = setInterval(() => {
      setAge(getAge());
    }, 80);

    return () => clearInterval(interval);
  }, []);

  function getAge() {
    const birthDate = new Date("1995-06-05T05:18:00.000");
    const today = new Date();
    const ageInMilliseconds = Number(today) - Number(birthDate);
    const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
    return ageInYears.toFixed(10);
  }

  // Normalize to life avg expectancy (80)
  // const normalise = (value: number) => (value * 100) / 80;

  const SpanStyle = styled("span")(({ theme }) => ({
    float: "right",
    fontFamily: "Courier",
    color: theme.palette.warning.main,
    fontWeight: theme.typography.h6.fontWeight,
    fontSize: theme.typography.h6.fontSize,
  }));

  return (
    <SpanStyle>
      {age}
      {/* <LinearProgress
        variant="determinate"
        value={normalise(Number(getAge()))}
        color="warning"
      /> */}
    </SpanStyle>
  );
}
