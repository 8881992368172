import { API_NAMESPACE, OPEN_AI_API_KEY } from "../theme/ConstantsProvider";

function getFromLocalStorage(namespace: string, key: string): any {
  let ls: { [key: string]: any } = {};
  if (global.localStorage) {
    try {
      // @ts-ignore
      ls = JSON.parse(global.localStorage.getItem(namespace)) || {};
    } catch (e) {}
  }
  return ls[key];
}

function saveToLocalStorage(namespace: string, key: string, value: any): any {
  if (global.localStorage) {
    global.localStorage.setItem(
      namespace,
      JSON.stringify({
        [key]: value,
      })
    );
  }
}

export function setOpenAiApiKeyLocalStorage(key: string) {
  saveToLocalStorage(API_NAMESPACE, OPEN_AI_API_KEY, key);
}

export function getOpenAiApiKeyLocalStorage(): string {
  return getFromLocalStorage(API_NAMESPACE, OPEN_AI_API_KEY);
}
