import React, { useState } from "react";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Slider from "@mui/material/Slider";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import OpenAiApiClient from "../../api/OpenAiApiClient";
import Alert from "@mui/material/Alert";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import IosShareIcon from "@mui/icons-material/IosShare";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

interface ItineraryItem {
  time: string;
  activity: string;
  location: string;
  description: string;
  approximateCost: string;
  distanceFromPreviousLocation: string;
}

interface Itinerary {
  date: string;
  location: string;
  itinerary: ItineraryItem[];
}

const SYSTEM_PROMPT = `You are a helpful travel guide. You speak in only json.`;

export default function ActivitiesWidget({
  openAiApi,
}: {
  openAiApi: OpenAiApiClient | undefined;
}) {
  const [itinerary, setItinerary] = useState<Itinerary>();

  return (
    <div>
      {itinerary ? (
        <ItineraryDisplay
          openAiApi={openAiApi!}
          itinerary={itinerary}
          setItinerary={setItinerary}
        />
      ) : (
        <ActivityWidgetForm
          openAiApi={openAiApi!}
          setItinerary={setItinerary}
        />
      )}
    </div>
  );
}

function ItineraryDisplay({
  openAiApi,
  itinerary,
  setItinerary,
}: {
  openAiApi: OpenAiApiClient;
  itinerary: Itinerary;
  setItinerary: Function;
}) {
  const [copied, setCopied] = useState(false);
  const [loadingUpdateActivity, setLoadingUpdateActivity] = useState("");
  const [isErrorUpdate, setIsErrorUpdate] = useState(false);

  const onClear = () => {
    setItinerary(undefined);
  };

  const onShare = () => {
    navigator.clipboard
      .writeText(JSON.stringify(itinerary, null, 2))
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 4000);
      });
  };

  const onUpdateSuggestion = (item: ItineraryItem) => {
    const user_prompt = generateUpdatePrompt(JSON.stringify(item));
    setLoadingUpdateActivity(item.activity);

    const response = openAiApi.createChatCompletion(
      SYSTEM_PROMPT,
      user_prompt,
      1
    );

    response.then((res) => {
      if (res) {
        try {
          const newSuggestion: ItineraryItem = JSON.parse(res);
          updateItineraryWithNewSuggestion(newSuggestion, item);
          setLoadingUpdateActivity("");
        } catch (error) {
          console.log("Invalid response from ChatGPT");
          setIsErrorUpdate(true);
        }
      } else {
        console.log("Empty response from ChatGPT");
        setIsErrorUpdate(true);
      }
    });
  };

  const updateItineraryWithNewSuggestion = (
    newSuggestion: ItineraryItem,
    oldSuggestion: ItineraryItem
  ) => {
    if (
      itinerary.itinerary.some(
        (item: ItineraryItem) => item.activity === newSuggestion.activity
      )
    ) {
      throw new Error("Duplicate activity suggested");
    }

    const updatedItinerary = [...itinerary.itinerary];
    const activityIndex = updatedItinerary.findIndex(
      (activity) =>
        activity.activity === oldSuggestion.activity &&
        activity.time === oldSuggestion.time
    );
    updatedItinerary[activityIndex] = newSuggestion;
    setItinerary({ ...itinerary, itinerary: updatedItinerary });
  };

  React.useEffect(() => {
    if (isErrorUpdate)
      setTimeout(() => {
        setIsErrorUpdate(false);
        setLoadingUpdateActivity("");
      }, 4000);
  }, [isErrorUpdate]);

  const generateUpdatePrompt = (itemJson: string) => {
    return (
      "Suggest a new different activity at the same time for the below json, keep the json keys the same in the new suggestion: " +
      itemJson
    );
  };

  return (
    <Box>
      <div id="itinerary-data" style={{ whiteSpace: "pre-line" }}>
        <Typography variant="h5">Your Trip - {itinerary.location}</Typography>
        {/* <br/> */}
        {itinerary.itinerary.map((item, index) => {
          return (
            <Box key={item.activity}>
              <Typography variant="h5" sx={{ fontSize: "1.2rem", mt: 4 }}>
                {item.time}:{" "}
                <LinkifySubstring
                  text={item.activity}
                  search={item.location}
                  link={
                    "https://www.google.com/maps/search/" +
                    (
                      item.location +
                      " " +
                      itinerary.location.replace(",", "")
                    ).replace(" ", "+")
                  }
                />
              </Typography>
              <Typography variant="body2">{item.description}</Typography>
              <Chip
                label={item.approximateCost}
                variant="outlined"
                sx={{ mt: 1, mr: 1 }}
              />
              {index !== 0 && (
                <Chip
                  label={
                    item.distanceFromPreviousLocation +
                    " from previous location"
                  }
                  variant="outlined"
                  sx={{ mt: 1, mr: 1 }}
                />
              )}
              <Chip
                label={
                  loadingUpdateActivity === item.activity
                    ? "Loading..."
                    : "New Suggestion"
                }
                variant="outlined"
                color="secondary"
                sx={{ mt: 1, cursor: "pointer" }}
                onClick={() => onUpdateSuggestion(item)}
              />
              {isErrorUpdate && loadingUpdateActivity === item.activity && (
                <Alert sx={{ mt: 2 }} severity="error">
                  There was an error updating your itinerary. Check your search
                  & API key.
                </Alert>
              )}
            </Box>
          );
        })}
      </div>
      <br />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ mr: 1 }}
          onClick={onShare}
        >
          <IosShareIcon />
          &nbsp;{"Share"}
        </Button>
        <Button
          variant="contained"
          color="warning"
          sx={{ ml: 1 }}
          onClick={onClear}
        >
          <RestartAltIcon />
          &nbsp;{"Reset"}
        </Button>
      </Box>
      {copied && (
        <Alert sx={{ mt: 2 }} severity="success">
          Copied itinerary to cliboard.
        </Alert>
      )}
    </Box>
  );
}

const LinkifySubstring = ({
  text,
  search,
  link,
}: {
  text: string;
  search: string;
  link: string;
}) => {
  const index = text.toLowerCase().indexOf(search.toLowerCase());
  if (index === -1) {
    return <>{text}</>;
  }
  const before = text.substring(0, index);
  const match = text.substring(index, index + search.length);
  const after = text.substring(index + search.length);
  return (
    <>
      {before}
      <Link target="_blank" href={link}>
        {match}
      </Link>
      {after}
    </>
  );
};

function ActivityWidgetForm({
  openAiApi,
  setItinerary,
}: {
  openAiApi: OpenAiApiClient;
  setItinerary: Function;
}) {
  const [budgetValue, setBudgetValue] = useState<number>(35);
  const [noPreferenceCost, setNoPreferenceCost] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>("");
  const [selectedActivityGroup, setSelectedActivityGroup] =
    useState<string>("myself");
  const [selectedPosition, setSelectedPosition] = useState<string>("either");
  const [selectedPreferences, setSelectedPreferences] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isErrorSubmit, setIsErrorSubmit] = useState<boolean>(false);

  const onSubmitClick = () => {
    if (!searchText) {
      setIsErrorSubmit(true);
      return;
    }
    setIsLoading(true);

    const user_prompt = generateUserPrompt();
    //console.log("Prompt: " + user_prompt);

    const response = openAiApi.createChatCompletion(SYSTEM_PROMPT, user_prompt); //  openAiApi.mockItenerarryCompletion();

    response.then((res) => {
      if (res) {
        // console.log("Response: " + res);
        try {
          const itinerary: Itinerary = JSON.parse(res);
          setItinerary(itinerary);
        } catch (error) {
          console.log("Invalid response from ChatGPT");
          setIsErrorSubmit(true);
        }
      } else {
        console.log("Empty response from ChatGPT");
        setIsErrorSubmit(true);
      }
      setIsLoading(false);
    });
  };

  const getBudgetString = () => {
    if (noPreferenceCost) return "";
    else if (budgetValue < 33) return "low cost";
    else if (budgetValue >= 34 && budgetValue < 66) return "average cost";
    else return "high cost";
  };

  const getSelectedPositionString = () => {
    if (selectedPosition === "either") return "";
    else if (selectedPosition === "inside") return "mostly indoor";
    else return "mostly outdoor";
  };

  const getSelectActivityGroupString = () => {
    if (selectedActivityGroup === "myself") return "myself";
    else if (selectedActivityGroup === "partner")
      return "myself and my partner";
    else if (selectedActivityGroup === "family") return "myself and my family";
    else return "myself and my friends";
  };

  React.useEffect(() => {
    if (isErrorSubmit) setTimeout(() => setIsErrorSubmit(false), 4000);
  }, [isErrorSubmit]);

  const generateUserPrompt = () => {
    return `Return a ${getBudgetString()} ${getSelectedPositionString()} 1 day itinerary for ${getSelectActivityGroupString()} in ${searchText}, where each itinerary object contains a time, activity, description, location, approximateCost and distanceFromPreviousLocation parameter. Include activities related to ${selectedPreferences.join(
      ", "
    )}. Example response: {"date": "${new Date()
      .toISOString()
      .slice(
        0,
        10
      )}", "location": "San Diego, California", "itinerary": [{"time": "9:00 AM", "activity": "Skydiving at Pacific Coast Skydiving", "location": "Pacific Coast Skydiving", "description": "Experience the thrill of skydiving with a ...", "approximateCost": "$$$", "distanceFromPreviousLocation": "5 miles"} ] }`;
  };

  return (
    <Box>
      <Typography variant="h5">Location</Typography>
      <LocationPreference
        searchText={searchText}
        setSearchText={setSearchText}
        selectedActivityGroup={selectedActivityGroup}
        setSelectedActivityGroup={setSelectedActivityGroup}
        selectedPosition={selectedPosition}
        setSelectedPosition={setSelectedPosition}
      />
      <br />
      <Typography variant="h5">Budget</Typography>
      <BudgetSlider
        budgetValue={budgetValue}
        setBudgetValue={setBudgetValue}
        noPreferenceCost={noPreferenceCost}
        setNoPreferenceCost={setNoPreferenceCost}
      />
      <br />
      <Typography variant="h5">Preferences</Typography>
      <ActivityPreferences
        selectedPreferences={selectedPreferences}
        setSelectedPreferences={setSelectedPreferences}
      />
      <br />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ width: "50%" }}
          disabled={isLoading}
          onClick={onSubmitClick}
        >
          {isLoading ? "Loading..." : "Submit"}
        </Button>
      </Box>
      {isErrorSubmit && (
        <Alert sx={{ mt: 2 }} severity="error">
          There was an error submitting your search. Check your search & API
          key.
        </Alert>
      )}
    </Box>
  );
}

function ActivityPreferences({
  selectedPreferences,
  setSelectedPreferences,
}: {
  selectedPreferences: string[];
  setSelectedPreferences: Function;
}) {
  const handlePreferencesChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const newPreferences = [...selectedPreferences];

    if (newPreferences.includes(value)) {
      newPreferences.splice(newPreferences.indexOf(value), 1);
    } else {
      newPreferences.push(value);
    }

    setSelectedPreferences(newPreferences);
  };

  const preferences = [
    { id: "adventure", name: "Adventure" },
    { id: "arts & culture", name: "Arts & Culture" },
    { id: "entertainment", name: "Entertainment" },
    { id: "family friendly", name: "Family Friendly" },
    { id: "food & drink", name: "Food & Drink" },
    { id: "history", name: "History" },
    { id: "nature", name: "Nature" },
    { id: "romantic", name: "Romantic" },
    { id: "shopping", name: "Shopping" },
    { id: "sports", name: "Sports" },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormGroup row={true}>
          {preferences.map((preference) => {
            return (
              <FormControlLabel
                key={preference.id}
                control={
                  <Checkbox
                    color="warning"
                    checked={selectedPreferences.includes(preference.id)}
                    onChange={handlePreferencesChange}
                    value={preference.id}
                  />
                }
                label={preference.name}
              />
            );
          })}
        </FormGroup>
      </Grid>
    </Grid>
  );
}

function LocationPreference({
  searchText,
  setSearchText,
  selectedPosition,
  setSelectedPosition,
  selectedActivityGroup,
  setSelectedActivityGroup,
}: {
  searchText: string;
  setSearchText: Function;
  selectedPosition: string;
  setSelectedPosition: Function;
  selectedActivityGroup: string;
  setSelectedActivityGroup: Function;
}) {
  const handleSearchLocationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchText(event.target.value);
  };

  const handlePositionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPosition(event.target.value);
  };

  const handleActivityGroupChange = (
    _: React.MouseEvent<HTMLElement>,
    newActivityGroup: string
  ) => {
    setSelectedActivityGroup(newActivityGroup);
  };

  return (
    <Grid container spacing={5} rowSpacing={2} alignItems="center">
      <Grid item xs={12} sm={8}>
        <TextField
          label="Search"
          value={searchText}
          onChange={handleSearchLocationChange}
          variant="outlined"
          fullWidth
        />
        <ToggleButtonGroup
          value={selectedActivityGroup}
          color="primary"
          exclusive
          onChange={handleActivityGroupChange}
          aria-label="text alignment"
          sx={{ mt: 2 }}
          defaultValue={"myself"}
        >
          <ToggleButton value="myself" aria-label="myself">
            Myself
          </ToggleButton>
          <ToggleButton value="partner" aria-label="partner">
            Partner
          </ToggleButton>
          <ToggleButton value="family" aria-label="family">
            Family
          </ToggleButton>
          <ToggleButton value="friends" aria-label="friends">
            Friends
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={12} sm={4}>
        <RadioGroup
          aria-label="position"
          name="position"
          value={selectedPosition}
          onChange={handlePositionChange}
          defaultValue="either"
        >
          <FormControlLabel
            value="inside"
            control={<Radio color="primary" />}
            label="Inside"
            labelPlacement="end"
          />
          <FormControlLabel
            value="outside"
            control={<Radio color="primary" />}
            label="Outside"
            labelPlacement="end"
          />
          <FormControlLabel
            value="either"
            control={<Radio color="primary" />}
            label="Either"
            labelPlacement="end"
          />
        </RadioGroup>
      </Grid>
    </Grid>
  );
}

function BudgetSlider({
  budgetValue,
  setBudgetValue,
  noPreferenceCost,
  setNoPreferenceCost,
}: {
  budgetValue: number;
  setBudgetValue: Function;
  noPreferenceCost: boolean;
  setNoPreferenceCost: Function;
}) {
  const handleChange = (_: any, newValue: number | number[]) => {
    setBudgetValue(newValue as number);
  };

  const handleToggle = (event: any) => {
    setNoPreferenceCost(event.target.checked);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={6}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={noPreferenceCost}
                onChange={handleToggle}
                color="secondary"
                name="noPreference"
                inputProps={{ "aria-label": "No preference toggle button" }}
              />
            }
            label="No Preference"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={6}>
        <Slider
          min={0}
          max={100}
          value={budgetValue}
          onChange={handleChange}
          disabled={noPreferenceCost}
          aria-labelledby="input-slider"
          color="secondary"
        />
      </Grid>
    </Grid>
  );
}
