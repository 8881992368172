import React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import ScrollToTop from "./components/ScrollToTop";
import ThemeProvider from "./theme/ThemeProvider";
import ReactGA from "react-ga4";

try {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID!);
} catch (error) {
  console.log("Unable to set up GA:" + error);
}

export default function App() {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <ScrollToTop />
        <Router />
      </ThemeProvider>
    </BrowserRouter>
  );
}
