import { OpenAI } from "openai";

export interface OpenAiApiFunctions {
  openAiApiKey: String;
  setOpenAiApiKey: Function;
  resetOpenAiApiKey: Function;
}

export default class OpenAiApiClient {
  private readonly openAiApi: OpenAI;

  constructor(openAiApiKey: string) {
    this.openAiApi = new OpenAI({
      apiKey: openAiApiKey,
      dangerouslyAllowBrowser: true,
    });
    console.log("Created OpenAIApiClient");
  }

  async createChatCompletion(
    system_prompt: string,
    user_prompt: string,
    temperature: number = 0.9
  ): Promise<string | undefined> {
    if (!this.openAiApi) throw new Error("OpenAIApi not found.");
    if (
      !system_prompt ||
      system_prompt.length === 0 ||
      !user_prompt ||
      user_prompt.length === 0
    )
      throw new Error("Invalid prompt.");

    try {
      const completion = await this.openAiApi.chat.completions.create({
        model: "gpt-4o-mini",
        messages: [
          { role: "system", content: system_prompt },
          { role: "user", content: user_prompt },
        ],
        temperature: temperature,
        response_format: { type: "json_object" },
      });
      // console.log(completion)
      return completion.choices[0].message!.content!;
    } catch (error) {
      console.log("Invalid response from ChatGPT");
      console.log(error);
    }
  }

  async generateImage(
    caption: string,
    size:
      | "256x256"
      | "512x512"
      | "1024x1024"
      | "1792x1024"
      | "1024x1792"
      | null
      | undefined = "1024x1024",
    use_dalle3: boolean = false,
    quality: "standard" | "hd" | undefined = undefined
  ): Promise<string | undefined> {
    if (!this.openAiApi) throw new Error("OpenAIApi not found.");
    if (!caption || caption.length === 0) throw new Error("Invalid prompt.");

    try {
      const completion = await this.openAiApi.images.generate({
        model: use_dalle3 ? "dall-e-3" : "dall-e-2",
        size: size,
        prompt: caption,
        response_format: "b64_json",
        quality: quality,
      });
      return completion.data[0].b64_json!;
    } catch (error) {
      console.log("Invalid response from ChatGPT");
      console.log(error);
    }
  }

  /*
  async mockItenerarryCompletion(): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
      resolve(
        `{"date": "2021-04-15", "location": "Bangalore, India", "itinerary": [{"time": "9:00 AM", "activity": "White water rafting at Bheemeshwari", "location": "Bheemeshwari", "description": "Take a thrilling white-water rafting experience on the Cauvery River at Bheemeshwari, located 100 km from Bangalore. Enjoy the breathtaking scenery and challenge the rapids!", "approximateCost": "$$", "distanceFromPreviousLocation": "100 km"}, {"time": "12:00 PM", "activity": "Lunch at Chikka Tirupathi", "location": "Chikka Tirupathi", "description": "Enjoy a delicious and traditional lunch at Chikka Tirupathi. The restaurant serves traditional South Indian cuisine.", "approximateCost": "$", "distanceFromPreviousLocation": "10 km"}, {"time": "2:00 PM", "activity": "Visit the Bangalore Palace", "location": "Bangalore Palace", "description": "Visit the majestic Bangalore Palace, built in 1887. Explore the royal grounds and marvel at the Indo-Saracenic architecture.", "approximateCost": "$$", "distanceFromPreviousLocation": "15 km"}, {"time": "4:00 PM", "activity": "Visit the Lalbagh Botanical Garden", "location": "Lalbagh Botanical Garden", "description": "Visit the stunning Lalbagh Botanical Garden, one of the oldest botanical gardens in India. Spend some time admiring the lush vegetation and variety of flora.", "approximateCost": "$", "distanceFromPreviousLocation": "5 km"}, {"time": "6:00 PM", "activity": "Dinner at Koshy's", "location": "Koshy's", "description": "Enjoy dinner at Koshy's, a Bangalore institution. The restaurant serves both Indian and Western dishes.", "approximateCost": "$$", "distanceFromPreviousLocation": "3 km"} ] }`
      );
    });
  }

  async mockDishesCompletion(): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
      resolve(
        `{"dishes": [{"name": "Teriyaki Salmon", "cooking_time": "25 minutes", "description": "A classic Japanese dish featuring salmon fillets glazed with a sweet and savory teriyaki sauce."}, {"name": "Miso-Glazed Black Cod", "cooking_time": "30 minutes", "description": "This dish features buttery black cod fillets marinated in a miso glaze and broiled until tender and lightly caramelized."}, {"name": "Sushi Rolls", "cooking_time": "45 minutes", "description": "A sushi roll is a Japanese dish that typically consists of vinegar-flavored sushi rice and a variety of ingredients, such as raw fish, vegetables, and avocado, all rolled up in sheets of nori seaweed."}, {"name": "Tempura Shrimp", "cooking_time": "20 minutes", "description": "These crispy battered and deep-fried shrimp are a staple of Japanese cuisine. They are often served as a side dish or as an ingredient in sushi rolls."}, {"name": "Yakitori Skewers", "cooking_time": "30 minutes", "description": "Yakitori is a Japanese dish that features skewered and grilled chicken, often seasoned with soy sauce, sake, and mirin. The skewers are typically served with a side of rice."}, {"name": "Grilled Mackerel", "cooking_time": "15 minutes", "description": "Mackerel is a common fish in Japanese cuisine. It is often grilled and served with a side of pickled vegetables and rice. The fish is usually seasoned with salt, soy sauce, or a miso-based marinade."}, {"name": "Udon Noodle Soup with Shrimp Tempura", "cooking_time": "40 minutes", "description": "This hearty soup features thick udon noodles in a savory broth with shrimp tempura, fish cake, and sliced scallions. It is a staple of Japanese comfort food."} ] }`
      );
    });
  }

  async mockRecipeCompletion(): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
      resolve(
        `{"recipe_name": "Miso-Glazed Black Cod", "cooking_time": "30 minutes", "serving_size": "4", "ingredients": [{"name": "black cod fillets", "quantity": "4", "unit": "pieces"}, {"name": "white miso paste", "quantity": "1/4", "unit": "cup"}, {"name": "mirin", "quantity": "3", "unit": "tablespoons"}, {"name": "sake", "quantity": "3", "unit": "tablespoons"}, {"name": "brown sugar", "quantity": "2", "unit": "tablespoons"}, {"name": "vegetable oil", "quantity": "2", "unit": "tablespoons"}, {"name": "green onions", "quantity": "2", "unit": "pieces"}, {"name": "toasted white sesame seeds", "quantity": "1", "unit": "tablespoon"} ], "steps": ["Preheat the broiler to high.", "In a bowl, whisk together the miso paste, mirin, sake, and brown sugar until smooth.", "Brush both sides of the black cod fillets with the miso glaze, and let the fillets marinate for 10-15 minutes.", "Line a baking sheet with foil, and brush the foil with vegetable oil. Place the marinated black cod fillets on the prepared baking sheet, and broil until the tops are lightly charred and the fish is cooked through, about 8-10 minutes.", "Garnish the black cod fillets with sliced green onions and toasted white sesame seeds. Serve immediately."] }`
      );
    });
  }
  */

  async mockPrompts(): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
      resolve(
        `{ "prompts": [ { "title": "A Symphony of Light", "text": "An aurora borealis concert with musical notes visibly emanating from the lights.", "isLong": false }, { "title": "Steampunk Savannah", "text": "A steampunk-style mechanical giraffe grazing in an African savannah at sunset.", "isLong": false }, { "title": "Quantum Garden", "text": "A garden where the plants exhibit quantum superposition, phasing in and out.", "isLong": false }, { "title": "The Clockwork Metropolis", "text": "Imagine a sprawling metropolis where the buildings, vehicles, and citizens themselves are intricately crafted clockwork mechanisms. The skyline is a mesh of cog-shaped skyscrapers, brass piping, and glass domes. Steam-powered cars move in synchronized patterns, while the people are humanoid automatons with visible gears and springs, going about their daily routine. The air carries a faint scent of oil and metal, and the city is bathed in a warm, golden glow from the setting sun reflecting off the polished brass structures.", "isLong": true }, { "title": "The Library of Echoes", "text": "Deep within a misty forest, there exists a library carved into the trunk of an ancient, colossal tree. The library is a hollow spiral ascending up the tree's interior, filled with shelves of old, leather-bound books that hum faintly with the echoes of their stories. Ethereal librarians float between the shelves, cataloging the mystical tomes. The tree's roots form cozy reading nooks, and the gentle rustle of leaves blends with the whispers of turning pages. Sunbeams filter through the canopy, illuminating the dust motes that dance like tiny spirits in the sacred silence.", "isLong": true } ] }`
      );
    });
  }
}
