import { ReactNode, useState } from "react";
import OpenAiApiClient, { OpenAiApiFunctions } from "../../api/OpenAiApiClient";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { setOpenAiApiKeyLocalStorage } from "../../api/LocalEnvironmentClient";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

export default function OpenAiWidget({
  title,
  description,
  widget,
  openAiApi,
  openAiApiFunctions,
}: {
  title: String;
  description: String;
  widget: ReactNode;
  openAiApi: OpenAiApiClient | undefined;
  openAiApiFunctions: OpenAiApiFunctions;
}) {
  return (
    <Paper
      sx={{
        p: 4,
      }}
    >
      <Typography variant="h4" style={{ marginBottom: 15 }}>
        {title}
      </Typography>
      <Typography variant="body1">
        {description}
        {!openAiApiFunctions.openAiApiKey ? (
          <span> Enter your OpenAI API key to get started.</span>
        ) : (
          <span>
            {" "}
            Click{" "}
            <span
              style={{ color: "#5048E5", cursor: "pointer" }}
              onClick={() => openAiApiFunctions.resetOpenAiApiKey()}
            >
              here
            </span>{" "}
            to reset your OpenAI API key.
          </span>
        )}
      </Typography>
      <br />
      {openAiApi ? (
        widget
      ) : (
        <OpenAiApiKeyInput
          setOpenAiApiKey={openAiApiFunctions.setOpenAiApiKey}
        />
      )}
    </Paper>
  );
}

function OpenAiApiKeyInput({ setOpenAiApiKey }: { setOpenAiApiKey: Function }) {
  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (
      process.env.REACT_APP_OPENAI_API_KEY_PASS &&
      inputValue === process.env.REACT_APP_OPENAI_API_KEY_PASS &&
      process.env.REACT_APP_OPENAI_API_KEY
    ) {
      setOpenAiApiKey(process.env.REACT_APP_OPENAI_API_KEY);
      setOpenAiApiKeyLocalStorage(process.env.REACT_APP_OPENAI_API_KEY_PASS);
    } else {
      setOpenAiApiKey(inputValue);
      setOpenAiApiKeyLocalStorage(inputValue);
    }

    setInputValue("");
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={9}>
        <TextField
          label="OpenAI API Key"
          variant="outlined"
          fullWidth
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </Grid>
      <Grid item xs={3}>
        <Button variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}
