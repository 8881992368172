import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import "./Books.css";
import Chip from "@mui/material/Chip";

interface BookInfo {
  title: string;
  author: string;
  imageLink: string;
  amazonLink: string;
  description: string;
  dateRead: string;
  rating: string;
  tags: Array<string>;
  notes: string;
}

export default function BooksPage() {
  const [booksInfo, setBooksInfo] = React.useState<BookInfo[]>([]);
  const [sortKey, setSortKey] = React.useState("dateRead");
  const [searchKey, setSearchKey] = React.useState("");

  React.useEffect(() => {
    fetch("data/books.json")
      .then((res) => res.json())
      .then((data: BookInfo[]) => {
        const lowercaseSearchKey = searchKey.toLowerCase();
        data = data.filter(
          (bookInfo) =>
            bookInfo.title.toLowerCase().indexOf(lowercaseSearchKey) >= 0 ||
            bookInfo.description.toLowerCase().indexOf(lowercaseSearchKey) >=
              0 ||
            bookInfo.author.toLowerCase().indexOf(lowercaseSearchKey) >= 0
        );

        if (sortKey === "title") {
          setBooksInfo(data.sort((b1, b2) => (b1.title > b2.title ? 1 : -1)));
        } else if (sortKey === "author") {
          setBooksInfo(data.sort((b1, b2) => (b1.author > b2.author ? 1 : -1)));
        } else if (sortKey === "rating") {
          setBooksInfo(data.sort((b1, b2) => (b1.rating > b2.rating ? 1 : -1)));
        } else {
          setBooksInfo(
            data.sort((b1, b2) => (b1.dateRead > b2.dateRead ? -1 : 1))
          );
        }
      });
  }, [sortKey, searchKey]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid
        container
        spacing={3}
        rowSpacing={3}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={8}>
          <BooksCardHeader
            setSortKey={setSortKey}
            setSearchKey={setSearchKey}
          />
        </Grid>
        {booksInfo.map((bookInfo) => (
          <Grid item xs={12} md={8} key={bookInfo.title}>
            <BookCard bookInfo={bookInfo}></BookCard>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

function BookCard({ bookInfo }: { bookInfo: BookInfo }) {
  const theme = useTheme();
  const PRIMARY_GREY = theme.palette.grey["500"];

  return (
    <Paper
      sx={{
        p: 4,
        alignItems: "left",
      }}
    >
      <a href={bookInfo.amazonLink} target="_blank" rel="noopener noreferrer">
        <img
          src={bookInfo.imageLink}
          alt={bookInfo.title}
          className="book-cover"
        />
      </a>
      <Typography className="book-title" variant="h4">
        {bookInfo.title}
      </Typography>
      <Typography className="book-author" variant="h6" color={PRIMARY_GREY}>
        {bookInfo.author}
      </Typography>
      <br />
      <Typography className="book-description" variant="body2">
        {bookInfo.description}
      </Typography>

      <Typography
        className="book-rating"
        component="div"
        variant="body2"
        color={PRIMARY_GREY}
      >
        Read:&nbsp;{bookInfo.dateRead}&nbsp;&nbsp;
        {bookInfo.rating && `| Recommend: ${bookInfo.rating}/10 `}
        {/* Uncomment for regular font instead of chips */}
        {/* {bookInfo.tags &&
          bookInfo.tags.length > 0 &&
          `| ${bookInfo.tags.join(", ")}`} */}
        {bookInfo.tags.map((tag) => (
          <Chip key={tag} label={tag} variant="outlined" sx={{ mr: "8px" }} />
        ))}
      </Typography>
    </Paper>
  );
}

function BooksCardHeader({
  setSortKey,
  setSearchKey,
}: {
  setSortKey: Function;
  setSearchKey: Function;
}) {
  return (
    <Paper
      sx={{
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
      }}
    >
      <Typography variant="h3">Books</Typography>

      <Typography variant="body1">
        Not all books are worth finishing.
      </Typography>
      <Typography variant="body1">
        {" "}
        The following is a list of books that I found worth reading to the last
        page.
      </Typography>
      <br />
      <FormControl>
        <FormLabel id="booksort">Sort By</FormLabel>
        <RadioGroup
          row
          aria-labelledby="booksort"
          name="sort-books-by"
          defaultValue="dateRead"
          onChange={(_, value) => setSortKey(value)}
        >
          <FormControlLabel
            value="dateRead"
            control={<Radio />}
            label="Date Read"
          />
          <FormControlLabel value="title" control={<Radio />} label="Title" />
          {/* <FormControlLabel value="rating" control={<Radio />} label="Rating" /> */}
          {/* <FormControlLabel value="author" control={<Radio />} label="Author" /> */}
        </RadioGroup>
      </FormControl>
      <br />
      <TextField
        id="searchBooks"
        label="Search this page"
        variant="outlined"
        onChange={(event) => setSearchKey(event.target.value)}
      />
    </Paper>
  );
}
