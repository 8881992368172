import RestartAltIcon from "@mui/icons-material/RestartAlt";
// import FileUploadIcon from '@mui/icons-material/FileUpload';
import RefreshIcon from "@mui/icons-material/Refresh";
import CreateIcon from "@mui/icons-material/Create";
import Grid from "@mui/material/Grid";
import OpenAiApiClient from "../../api/OpenAiApiClient";
import { useEffect, useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";

export default function ImageGeneratorWidget({
  openAiApi,
}: {
  openAiApi: OpenAiApiClient | undefined;
}) {
  const [imageB64, setImageB64] = useState<string>();
  const [captionText, setCaptionText] = useState<string>("");

  const resetImage = () => {
    setImageB64("");
    setCaptionText("");
  };

  return (
    <div>
      {!imageB64 ? (
        <ImageGeneratorForm
          openAiApi={openAiApi!}
          captionText={captionText}
          setCaptionText={setCaptionText}
          setImageB64={setImageB64}
        />
      ) : (
        <ImageDisplay
          imageB64={imageB64!}
          caption={captionText}
          resetImage={resetImage}
        />
      )}
    </div>
  );
}

function ImageDisplay({
  imageB64,
  caption,
  resetImage,
}: {
  imageB64: string;
  caption: string;
  resetImage: Function;
}) {
  // function toTitleCase(str: string) {
  //   return str.replace(
  //     /\w\S*/g,
  //     function(txt: string) {
  //       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  //     }
  //   );
  // }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={`data:image/png;base64,${imageB64}`}
            alt={caption}
            width={"80%"}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            mt: 2,
          }}
        >
          <Typography variant="h6">{caption}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Button
            variant="contained"
            color="warning"
            sx={{ width: "50%" }}
            onClick={() => resetImage()}
          >
            <RestartAltIcon />
            &nbsp;{"Reset"}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

interface ExamplePrompt {
  title: string;
  text: string;
  isLong: boolean;
}

interface ExamplePromptList {
  prompts: ExamplePrompt[];
}

function ImageGeneratorForm({
  openAiApi,
  captionText,
  setCaptionText,
  setImageB64,
}: {
  openAiApi: OpenAiApiClient;
  captionText: string;
  setCaptionText: Function;
  setImageB64: Function;
}) {
  const [isDallE3, setIsDallE3] = useState<boolean>(true);
  const [isHD, setIsHD] = useState<boolean>(false);
  const [size, setSize] = useState<
    | "256x256"
    | "512x512"
    | "1024x1024"
    | "1792x1024"
    | "1024x1792"
    | null
    | undefined
  >("1024x1024");
  const [preset, setPreset] = useState<string>("None");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGenerateLoading, setIsGenerateLoading] = useState<boolean>(false);
  const [isErrorSubmit, setIsErrorSubmit] = useState<boolean>(false);
  const [examplePrompts, setExamplePrompts] = useState<ExamplePrompt[]>([]);

  const getImageCaption = () => {
    return "None" === preset ? captionText : preset + ": " + captionText;
  };

  const onSubmitClick = () => {
    if (!captionText) {
      setIsErrorSubmit(true);
      return;
    }
    setIsLoading(true);

    const response = openAiApi.generateImage(
      getImageCaption(),
      size,
      isDallE3,
      isHD ? "hd" : undefined
    );

    response.then((res) => {
      if (res) {
        try {
          // console.log(res);
          setImageB64(res);
        } catch (error) {
          console.log("Invalid response from ChatGPT");
          setIsErrorSubmit(true);
        }
      } else {
        console.log("Empty response from ChatGPT");
        setIsErrorSubmit(true);
      }
      setIsLoading(false);
    });
  };

  const generatePrompts = () => {
    const user_prompt =
      "Generate 3 short prompts and 2 long prompts." +
      (captionText ? "Base the prompts around: " + captionText : "");
    const system_prompt = `
      You are an expert image prompt generator. You think of creative prompts for images, short and long. 
      Each image prompt should be unique, thoughtful and interesting.
      Occasionally ombine elements from various domains. 
      Apply unique perpectives and filters if they make sense (such as 'photo realistic' or 'ink pen') - weave them into the description.
      Keep short prompts less than one line, and elaborate (be descriptive) on long prompts.
      Respond as a json array with each prompt containing a title, text, and isLong filed.
    `;
    setIsGenerateLoading(true);

    const response = openAiApi.createChatCompletion(
      system_prompt,
      user_prompt,
      0.8
    ); // openAiApi.mockPrompts();
    response.then((res) => {
      if (res) {
        try {
          const promptList: ExamplePromptList = JSON.parse(res);
          setExamplePrompts(promptList.prompts);
        } catch (error) {
          console.log("Invalid response from ChatGPT");
          setIsErrorSubmit(true);
        }
      } else {
        console.log("Empty response from ChatGPT");
        setIsErrorSubmit(true);
      }
      setIsGenerateLoading(false);
    });
  };

  useEffect(() => {
    if (isErrorSubmit) setTimeout(() => setIsErrorSubmit(false), 4000);
  }, [isErrorSubmit]);

  const handleCaptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCaptionText(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      onSubmitClick();
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Preferences</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={isDallE3}
                onChange={() => setIsDallE3(!isDallE3)}
                color="secondary"
                name="noPreference"
                inputProps={{ "aria-label": "DALL-E 3 toggle button" }}
              />
            }
            label="Use DALL-E 3"
          />
        </FormGroup>
        {/* </Grid> */}
        {isDallE3 && (
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={isHD}
                  onChange={() => setIsHD(!isHD)}
                  color="secondary"
                  name="noPreference"
                  inputProps={{ "aria-label": "Use HD toggle button" }}
                />
              }
              label="Use HD"
            />
          </FormGroup>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <SizeForm isDallE3={isDallE3} size={size} setSize={setSize} />
      </Grid>
      <Grid item xs={6}>
        <ImagePresets preset={preset} setPreset={setPreset} />
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" justifyContent="flex-end">
          <Tooltip
            title={
              isGenerateLoading ? "Loading..." : "Generate Example Prompts"
            }
            color="secondary"
          >
            <span>
              <Fab
                size="medium"
                disabled={isGenerateLoading}
                variant="extended"
                color="secondary"
                aria-label="generate-prompts"
                onClick={generatePrompts}
              >
                {isGenerateLoading ? <RefreshIcon /> : <CreateIcon />}
              </Fab>
            </span>
          </Tooltip>
          {/* <Tooltip title="Upload Starter File" color="secondary">
            <Fab size="medium" variant="extended" color="secondary" aria-label="upload-file" sx={{ ml: 2 }}>
              <FileUploadIcon />
            </Fab>
          </Tooltip> */}
        </Box>
      </Grid>
      {examplePrompts.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h5">Example Prompts</Typography>
          <Box sx={{ textAlign: "left", fontStyle: "italic" }}>
            {examplePrompts.map((prompt) => {
              return (
                <Typography key={prompt.title} variant="body1" sx={{ mb: 2 }}>
                  {prompt.text}
                </Typography>
              );
            })}
          </Box>
        </Grid>
      )}

      <Grid item xs={12}>
        <TextField
          label="Caption"
          value={captionText}
          onChange={handleCaptionChange}
          variant="outlined"
          onKeyDown={handleKeyPress}
          fullWidth
        />
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: "50%" }}
            disabled={isLoading}
            onClick={onSubmitClick}
          >
            {isLoading ? "Loading..." : "Submit"}
          </Button>
        </Box>
        {isErrorSubmit && (
          <Alert sx={{ mt: 2 }} severity="error">
            There was an error submitting your search. Check your search & API
            key.
          </Alert>
        )}
      </Grid>
    </Grid>
  );
}

function ImagePresets({
  preset,
  setPreset,
}: {
  preset: string;
  setPreset: Function;
}) {
  const presets = [
    { name: "Cartoonify", id: "cartoonify" },
    { name: "Comic Book Halftone", id: "comic_book_halftone" },
    { name: "Cybernetic Circuitry", id: "cybernetic_circuitry" },
    { name: "Desert Mirage", id: "desert_mirage" },
    { name: "Double Exposure Magic", id: "double_exposure_magic" },
    { name: "Dreamy Blur", id: "dreamy_blur" },
    { name: "Film Noir Noir", id: "film_noir_noir" },
    { name: "Fire and Ice", id: "fire_and_ice" },
    { name: "Fractal Fantasy", id: "fractal_fantasy" },
    { name: "Galactic Glitch", id: "galactic_glitch" },
    { name: "Glitch Art Extravaganza", id: "glitch_art_extravaganza" },
    { name: "HDR Explosion", id: "hdr_explosion" },
    { name: "Ink Sketch", id: "ink_sketch" },
    { name: "Mirror Kaleidoscope", id: "mirror_kaleidoscope" },
    { name: "Mirror Mirror", id: "mirror_mirror" },
    { name: "Mosaic Madness", id: "mosaic_madness" },
    { name: "Negative Reality", id: "negative_reality" },
    { name: "None", id: "none" },
    { name: "Neon Glow", id: "neon_glow" },
    { name: "Oil Painting Elegance", id: "oil_painting_elegance" },
    { name: "Photo Realistic", id: "photo_realistic" },
    { name: "Pop Art Explosion", id: "pop_art_explosion" },
    { name: "Puzzle Pieces", id: "puzzle_pieces" },
    { name: "Rainbow Hues", id: "rainbow_hues" },
    { name: "Soft Pastel Glow", id: "soft_pastel_glow" },
    { name: "Solarize Surprise", id: "solarize_surprise" },
    { name: "Sunset Silhouette", id: "sunset_silhouette" },
    { name: "Time Warp", id: "time_warp" },
    { name: "Topographic Terrain", id: "topographic_terrain" },
    { name: "Underpainting Delight", id: "underpainting_delight" },
    { name: "Watercolor Wonderland", id: "watercolor_wonderland" },
  ];

  const handPresetChange = (event: SelectChangeEvent<string>) => {
    setPreset(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="image-preset-select-label">Preset</InputLabel>
      <Select
        labelId="image-preset-select-label"
        id="image-preset-select"
        value={preset}
        label="Preset"
        onChange={handPresetChange}
      >
        {presets.map((presetInner) => {
          return (
            <MenuItem key={presetInner.id} value={presetInner.name}>
              {presetInner.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

function SizeForm({
  isDallE3,
  size,
  setSize,
}: {
  isDallE3: boolean;
  size:
    | "256x256"
    | "512x512"
    | "1024x1024"
    | "1792x1024"
    | "1024x1792"
    | null
    | undefined;
  setSize: Function;
}) {
  const handleSizeChange = (
    _: React.MouseEvent<HTMLElement>,
    newSize: string
  ) => {
    setSize(newSize);
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      {isDallE3 ? (
        <ToggleButtonGroup
          value={size}
          color="warning"
          exclusive
          onChange={handleSizeChange}
          sx={{ mt: 2 }}
          defaultValue={"1024x1024"}
        >
          <ToggleButton value="1792x1024" aria-label="1792x1024">
            Landscape
          </ToggleButton>
          <ToggleButton value="1024x1792" aria-label="1024x1792">
            Portrait
          </ToggleButton>
          <ToggleButton value="1024x1024" aria-label="1024x1024">
            Square
          </ToggleButton>
        </ToggleButtonGroup>
      ) : (
        <ToggleButtonGroup
          value={size}
          color="warning"
          exclusive
          onChange={handleSizeChange}
          sx={{ mt: 2 }}
          defaultValue={"1024x1024"}
        >
          <ToggleButton value="256x256" aria-label="256x256">
            Small
          </ToggleButton>
          <ToggleButton value="512x512" aria-label="512x512">
            Medium
          </ToggleButton>
          <ToggleButton value="1024x1024" aria-label="1024x1024">
            Large
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </Box>
  );
}
