import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import ImageGrid from "../../components/ImageGrid";
import Typography from "@mui/material/Typography";
import { S3_PHOTO_BUCKET_URL } from "../../theme/ConstantsProvider";

const homeImages = [
  {
    img: S3_PHOTO_BUCKET_URL + "profile.jpg",
    title: "Profile",
  },
  {
    img: S3_PHOTO_BUCKET_URL + "surfing.jpg",
    title: "Surfing",
  },
  {
    img: S3_PHOTO_BUCKET_URL + "tahoe.jpg",
    title: "Tahoe",
  },
  {
    img: S3_PHOTO_BUCKET_URL + "fam-tree.jpg",
    title: "Siblings",
  },
  {
    img: S3_PHOTO_BUCKET_URL + "reef.jpg",
    title: "Hawaii",
  },
  {
    img: S3_PHOTO_BUCKET_URL + "skate.jpg",
    title: "Skate",
  },
  {
    img: S3_PHOTO_BUCKET_URL + "ny.jpg",
    title: "New York",
  },
  {
    img: S3_PHOTO_BUCKET_URL + "hwcanyon.jpg",
    title: "Hawaii Canyon",
  },
  {
    img: S3_PHOTO_BUCKET_URL + "vineyard.jpg",
    title: "Vineyard",
  },
  {
    img: S3_PHOTO_BUCKET_URL + "indiatemple.jpg",
    title: "India Temple",
  },
  {
    img: S3_PHOTO_BUCKET_URL + "marathon.jpg",
    title: "Marathon",
  },
  {
    img: S3_PHOTO_BUCKET_URL + "bostonbeer.jpg",
    title: "Boston",
  },
];

export default function HomePage() {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={8}>
          <BioCard />
          <ImageGrid imageData={homeImages} />
        </Grid>
      </Grid>
    </Container>
  );
}

function BioCard() {
  return (
    <Paper
      sx={{
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
      }}
    >
      <Typography variant="h3">Hi, I'm Adam.</Typography>

      <Typography variant="body1">
        Melbourne Beach native, currently living in San Diego.
      </Typography>
      <Typography variant="body1">Software engineer at Amazon.</Typography>
      <Typography variant="body1">
        I enjoy playing outside and spending time with good people.
      </Typography>
      <br />
      <Typography variant="body1">
        Check out what I'm <Link href="/books">reading</Link>,{" "}
        {/* <Link href="/blog"> writing</Link>,{" "} */}
        <Link href="/dashboard">coding</Link> or{" "}
        <Link href="/bio">a bit more about me</Link>.
      </Typography>
    </Paper>
  );
}
