import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import { useTheme } from "@mui/material/styles";
import MuiMarkdown from "mui-markdown";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

export interface BlogInfo {
  id: string;
  title: string;
  description?: string;
  contentSource: string;
  dateWritten: string;
  tags: Array<string>;
  views?: number;
  minRead?: number;
}

export default function BlogPage() {
  const [blogInfo, setBlogInfo] = React.useState<BlogInfo[]>([]);
  const [sortKey, setSortKey] = React.useState("newest");
  const [searchKey, setSearchKey] = React.useState("");

  React.useEffect(() => {
    fetch("data/blog.json")
      .then((res) => res.json())
      .then((data: BlogInfo[]) => {
        const lowercaseSearchKey = searchKey.toLowerCase();
        data = data.filter(
          (blogInfo) =>
            blogInfo.title.toLowerCase().indexOf(lowercaseSearchKey) >= 0 ||
            (blogInfo.description &&
              blogInfo.description.toLowerCase().indexOf(lowercaseSearchKey) >=
                0)
        );

        if (sortKey === "title") {
          setBlogInfo(data.sort((b1, b2) => (b1.title > b2.title ? 1 : -1)));
        } // TODO: add additional sort key for popularity
        else {
          setBlogInfo(
            data.sort((b1, b2) => (b1.dateWritten > b2.dateWritten ? -1 : 1))
          );
        }
      });
  }, [sortKey, searchKey]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid
        container
        spacing={3}
        rowSpacing={3}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={8}>
          <BlogCardHeader setSortKey={setSortKey} setSearchKey={setSearchKey} />
        </Grid>
        {blogInfo.map((blog) => (
          <Grid item xs={12} md={8} key={blog.title}>
            <BlogCard blog={blog}></BlogCard>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

function BlogCardHeader({
  setSortKey,
  setSearchKey,
}: {
  setSortKey: Function;
  setSearchKey: Function;
}) {
  return (
    <Paper
      sx={{
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
      }}
    >
      <Typography variant="h3">Blog</Typography>

      <Typography variant="body1">
        The purpose of this page is to inspire me to write in public.
      </Typography>
      <Typography variant="body1">Work in progress.</Typography>
      <br />
      <FormControl>
        <FormLabel id="blogSort">Sort By</FormLabel>
        <RadioGroup
          row
          aria-labelledby="blogSort"
          name="sort-blog-by"
          defaultValue="newest"
          onChange={(_, value) => setSortKey(value)}
        >
          <FormControlLabel value="newest" control={<Radio />} label="Newest" />
          <FormControlLabel
            value="popular"
            control={<Radio />}
            label="Popular"
          />
          <FormControlLabel value="title" control={<Radio />} label="Title" />
        </RadioGroup>
      </FormControl>
      <br />
      <TextField
        id="searchBlog"
        label="Search this page"
        variant="outlined"
        onChange={(event) => setSearchKey(event.target.value)}
      />
    </Paper>
  );
}

function BlogCard({ blog }: { blog: BlogInfo }) {
  const [preview, setPreview] = React.useState("");

  function limitPreview(text: string) {
    return text.split("\n").slice(0, 3).join("\n");
    // return text.substring(0, 300) + "...";
  }

  React.useEffect(() => {
    blog.description
      ? setPreview(blog.description)
      : fetch(`data/${blog.contentSource}`)
          .then((res) => res.text())
          .then((text) => {
            if (text) setPreview(limitPreview(text));
          });
  }, [blog.description, blog.contentSource]);

  const theme = useTheme();
  const PRIMARY_GREY = theme.palette.grey["500"];

  return (
    <Paper
      sx={{
        p: 4,
        alignItems: "left",
      }}
    >
      <Link href={`blog/${blog.id}`} underline="none" color="inherit">
        <BlogCardContent blog={blog} content={preview} />

        <br />
        <Typography variant="body1" color={PRIMARY_GREY}>
          Read More...
        </Typography>
      </Link>
    </Paper>
  );
}

export function BlogCardContent({
  blog,
  content,
}: {
  blog: BlogInfo;
  content: string;
}) {
  const theme = useTheme();
  const PRIMARY_GREY = theme.palette.grey["500"];

  return (
    <div>
      <Typography variant="h4">
        {blog.title}{" "}
        <span style={{ float: "right", fontWeight: 400 }}>
          {blog.tags.map((tag) => (
            <Chip label={tag} key={tag} variant="outlined" sx={{ ml: "8px" }} />
          ))}{" "}
        </span>
      </Typography>

      <Typography variant="h6" color={PRIMARY_GREY}>
        {blog.dateWritten} {blog.minRead && ` | ${blog.minRead} min read`}
      </Typography>
      <br />
      <Box style={{ whiteSpace: "pre-line" }}>
        <MuiMarkdown children={content} />
      </Box>
    </div>
  );
}
