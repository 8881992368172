import { useEffect, useState } from "react";
import { Image, ImageApiFactory } from "../../api/news";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { S3_IODT_PHOTO_BUCKET_URL } from "../../theme/ConstantsProvider";

const imageApi = ImageApiFactory();

export default function DailyImageWidget({ isoDate }: { isoDate: string }) {
  const [isFetchedImage, setIsFetchedImage] = useState(false);
  const [image, setImage] = useState<Image>();

  useEffect(() => {
    if (!isFetchedImage || isoDate !== image?.image_date) {
      fetchImage(isoDate);
    }
  }, [isFetchedImage, isoDate, image]);

  function fetchImage(isoDateString: string) {
    imageApi
      .getImage(isoDateString)
      .then((res) => {
        if (res.data) {
          const image: Image = res.data;
          setImage(image);
          setIsFetchedImage(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
        setIsFetchedImage(true); // Set the state to true even if there was an error to avoid infinite loops.
      });
  }

  return (
    <Paper
      sx={{
        p: 4,
      }}
    >
      {image ? (
        <div>
          <Typography variant="h4" style={{ marginBottom: 15 }}>
            Image of the Day - {image.title}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={`${S3_IODT_PHOTO_BUCKET_URL + image.title_key + ".png"}`}
              alt={image.title}
              width={"80%"}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              mt: 2,
            }}
          >
            <Typography variant="h6">{image.prompt}</Typography>
          </Box>
        </div>
      ) : (
        <div>
          {" "}
          <Typography variant="h4" style={{ marginBottom: 5 }}>
            Image of the Day - Loading...
          </Typography>
          <Typography variant="body1">
            {!isFetchedImage
              ? "Loading..."
              : "There is no image found for today's date."}
          </Typography>
        </div>
      )}
    </Paper>
  );
}
