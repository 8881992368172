import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { News, NewsApiFactory } from "../../api/news";
import { useEffect, useState } from "react";
import MuiMarkdown, { getOverrides } from "mui-markdown";
import { getTodayDateInPST } from "../utils/DateUtils";
import { similarity_score } from "../utils/StringUtils";

const newsApi = NewsApiFactory();

export default function DailyNewsWidget({ isoDate }: { isoDate: string }) {
  const [isFetchedNews, setIsFetchedNews] = useState(false);
  const [news, setNews] = useState<News>();

  useEffect(() => {
    if (!isFetchedNews || isoDate !== news?.news_date) {
      fetchNews(isoDate);
      // mockNews();
      // setIsFetchedNews(true);
    }
  }, [isFetchedNews, isoDate, news]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function mockNews() {
    const news = {
      news: "##### Takeaways from the first Republican presidential debate\nThe first Republican presidential primary debate took place, with Donald Trump absent. Eight candidates participated, with entrepreneur Vivek Ramaswamy being a central figure throughout the night. Trump's absence allowed his critics to confront Ramaswamy instead. However, the debate is unlikely to impact Trump's lead in the polls.\n\n##### Yevgeny Prigozhin: Wagner defied Putin and now its leader may be dead\nYevgeny Prigozhin, leader of the Wagner Group, may be dead. Prigozhin had publicly criticized Russia's military leadership and demanded their removal. The Russian elite are not mourning his reported demise.\n\n##### At Least 4 Killed, Including Gunman, in Biker Bar Shooting in Southern California\nA shooting occurred at a biker bar in Trabuco Canyon, California, resulting in four deaths, including the gunman. Six others were injured, with two in critical condition. The authorities are investigating the tragic event.\n\n##### Giuliani turns himself in on Georgia 2020 election charges after bond is set at $150,000\nRudy Giuliani, Donald Trump's lawyer, surrendered at a jail in Atlanta on charges related to efforts to overturn the 2020 presidential election in Georgia. Giuliani, along with Trump and 17 others, is accused of participating in a conspiracy to subvert the will of the voters. Giuliani's bond was set at $150,000.\n\n##### Trump attacks rivals in online interview with Tucker Carlson while skipping first presidential debate\nFormer President Donald Trump skipped the first Republican presidential debate and instead appeared in an online interview with Tucker Carlson. Trump criticized his rivals and defended his decision to not participate in the debate, citing his large lead in the polls and his perception of the network hosting the event.",
      news_date: getTodayDateInPST(),
      articles: [
        "At Least 4 Killed, Including Gunman, in Biker Bar Shooting in Southern California|https://news.google.com/rss/articles/CBMiUmh0dHBzOi8vd3d3Lm55dGltZXMuY29tLzIwMjMvMDgvMjQvdXMvc2hvb3RpbmctY29va3MtY29ybmVyLWJhci1vcmFuZ2UtY291bnR5Lmh0bWzSAQA?oc=5&hl=en-US&gl=US&ceid=US:en",
        "Giuliani turns himself in on Georgia 2020 election charges after bond is set at $150,000|https://news.google.com/rss/articles/CBMidGh0dHBzOi8vYXBuZXdzLmNvbS9hcnRpY2xlL3RydW1wLWdpdWxpYW5pLWdlb3JnaWEtZWxlY3Rpb24taW5kaWN0bWVudC1mdWx0b24tY291bnR5LTIwM2IxZTY5Y2JmZjIyN2EwYmY4Y2M1OWE2YmI2NDVm0gEA?oc=5&hl=en-US&gl=US&ceid=US:en",
        "Takeaways from the first Republican presidential debate|https://news.google.com/rss/articles/CBMiTmh0dHBzOi8vd3d3LmNubi5jb20vMjAyMy8wOC8yMy9wb2xpdGljcy90YWtlYXdheXMtcmVwdWJsaWNhbi1kZWJhdGUvaW5kZXguaHRtbNIBAA?oc=5&hl=en-US&gl=US&ceid=US:en",
        "Trump attacks rivals in online interview with Tucker Carlson while skipping first presidential debate|https://news.google.com/rss/articles/CBMiiwFodHRwczovL3d3dy5wYnMub3JnL25ld3Nob3VyL3BvbGl0aWNzL3RydW1wLWF0dGFja3Mtcml2YWxzLWluLW9ubGluZS1pbnRlcnZpZXctd2l0aC10dWNrZXItY2FybHNvbi13aGlsZS1za2lwcGluZy1maXJzdC1wcmVzaWRlbnRpYWwtZGViYXRl0gGPAWh0dHBzOi8vd3d3LnBicy5vcmcvbmV3c2hvdXIvYW1wL3BvbGl0aWNzL3RydW1wLWF0dGFja3Mtcml2YWxzLWluLW9ubGluZS1pbnRlcnZpZXctd2l0aC10dWNrZXItY2FybHNvbi13aGlsZS1za2lwcGluZy1maXJzdC1wcmVzaWRlbnRpYWwtZGViYXRl?oc=5&hl=en-US&gl=US&ceid=US:en",
        "Yevgeny Prigozhin: Wagner defied Putin and now its leader may be dead|https://news.google.com/rss/articles/CBMiLmh0dHBzOi8vd3d3LmJiYy5jb20vbmV3cy93b3JsZC1ldXJvcGUtNjY2MDE1NTPSATJodHRwczovL3d3dy5iYmMuY29tL25ld3Mvd29ybGQtZXVyb3BlLTY2NjAxNTUzLmFtcA?oc=5&hl=en-US&gl=US&ceid=US:en",
      ],
    };
    setNews(news);
  }

  function fetchNews(isoDateString: string) {
    function replaceNewsWithLinks(news: News) {
      const articles = news.articles;
      const pattern = /^##### (.*)$/gm;
      const matches = news.news.match(pattern);
      const headlines =
        matches?.map((match) => match.replace("##### ", "")) || [];

      for (const headline of headlines) {
        for (const article of articles) {
          const article_title = article.split("|")[0];
          const similarity = similarity_score(headline, article_title);
          if (similarity > 0.7) {
            news.news = news.news.replace(
              "##### " + headline,
              "##### [" + headline + "](" + article.split("|")[1] + ")"
            );
          }
        }
      }
    }

    newsApi
      .getNews(isoDateString)
      .then((res) => {
        if (res.data) {
          const news: News = res.data;
          replaceNewsWithLinks(news);
          setNews(res.data);
          setIsFetchedNews(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching news:", error);
        setIsFetchedNews(true); // Set the state to true even if there was an error to avoid infinite loops.
      });
  }

  function getHumanReadableDate(isoDate: string) {
    const dateObject = new Date(isoDate);
    const options: Intl.DateTimeFormatOptions = {
      month: "long",
      day: "numeric",
      year: "numeric",
      timeZone: "UTC",
    };
    return dateObject.toLocaleDateString("en-US", options);
  }

  return (
    <Paper
      sx={{
        p: 4,
      }}
    >
      {news ? (
        <div>
          <Typography variant="h4" style={{ marginBottom: 15 }}>
            Top Stories - {getHumanReadableDate(news.news_date)}
          </Typography>
          <MuiMarkdown
            overrides={{
              ...getOverrides(), // This will keep the other default overrides.
              h5: {
                props: {
                  style: {
                    fontWeight: 500,
                    fontSize: "1.08rem",
                    lineHeight: 1.5,
                    marginTop: "10px",
                    marginBottom: "5px",
                  },
                } as React.HTMLProps<HTMLParagraphElement>,
              },
              p: {
                props: {
                  style: {
                    color: "#374151",
                  },
                } as React.HTMLProps<HTMLParagraphElement>,
              },
            }}
            children={news.news}
          />
        </div>
      ) : (
        <div>
          {" "}
          <Typography variant="h4" style={{ marginBottom: 5 }}>
            Top Stories - {getHumanReadableDate(getTodayDateInPST())}
          </Typography>
          <Typography variant="body1">
            {!isFetchedNews
              ? "Loading..."
              : "There is no news found for today's date."}
          </Typography>
        </div>
      )}
    </Paper>
  );
}
