import { Grid, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import { ArrowBackIos, Email, Instagram, Twitter } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => {
  return {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
  };
});

export const DashboardNavbar = ({
  setIsSidebarOpen,
}: {
  setIsSidebarOpen: Function;
}) => {
  const { pathname } = useLocation();
  return (
    <DashboardNavbarRoot
      sx={{
        left: {
          lg: 280,
        },
        width: {
          lg: "calc(100% - 280px)",
        },
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          minHeight: 64,
          left: 0,
          px: 2,
        }}
      >
        <Tooltip title="Menu">
          <IconButton
            onClick={() => setIsSidebarOpen(true)}
            sx={{
              display: {
                xs: "inline-flex",
                lg: "none",
              },
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {pathname.includes("blog/") && (
          <Tooltip title="Back">
            <IconButton sx={{ ml: 1 }} href="/blog">
              <ArrowBackIos fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        {/* <Tooltip title="Search">
          <IconButton sx={{ ml: 1 }}>
            <SearchIcon fontSize="small" />
          </IconButton>
        </Tooltip> */}
        <Grid container justifyContent="flex-end" sx={{ mr: "5px" }}>
          <Tooltip title="Twitter">
            <IconButton
              sx={{ ml: 1 }}
              href="https://twitter.com/blanketrock"
              target="_blank"
            >
              <Twitter fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Instagram">
            <IconButton
              sx={{ ml: 1 }}
              href="https://www.instagram.com/blanketrock/"
              target="_blank"
            >
              <Instagram fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Email">
            <IconButton
              sx={{ ml: 1 }}
              href="mailto:blanketrock@mail.com"
              target="_blank"
            >
              <Email fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};
