import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import DailyNewsWidget from "../../components/widget/DailyNewsWidget";
import DailyLearningWidget from "../../components/widget/DailyLearningWidget";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { getTodayDateInPST } from "../../components/utils/DateUtils";
import DailyImageWidget from "../../components/widget/DailyImageWidget";

export default function DailyUpdatePage() {
  const [isoDate, setIsoDate] = useState(getTodayDateInPST());

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid
        container
        spacing={3}
        rowSpacing={3}
        justifyContent="center"
        alignItems="center"
      >
        <DailyUpdatesHeader isoDate={isoDate} setIsoDate={setIsoDate} />
        <DailyNewsWidgetContainer isoDate={isoDate} />
        <DailyLearningWidgetContainer isoDate={isoDate} />
        <DailyImageWidgetContainer isoDate={isoDate} />
      </Grid>
    </Container>
  );
}

const shouldDisableDate = (date: any) => {
  return date.isBefore("2023-08-28");
};

function DailyUpdatesHeader({
  isoDate,
  setIsoDate,
}: {
  isoDate: string;
  setIsoDate: Function;
}) {
  return (
    <Grid item xs={12} md={8}>
      <Paper
        sx={{
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
        }}
      >
        <Typography variant="h3">Today's Update</Typography>

        <Typography variant="body1">
          This page is refreshed daily with AI generated content.
        </Typography>
        <Typography variant="body1">
          Check back here for the latest news and interesting information about
          novel topics.
        </Typography>
        <br />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Look Back in Time"
            disableFuture={true}
            shouldDisableDate={shouldDisableDate}
            format="YYYY-MM-DD"
            value={dayjs(isoDate)}
            onChange={(newDate) =>
              setIsoDate(newDate?.toISOString().slice(0, 10))
            }
          />
        </LocalizationProvider>
      </Paper>
    </Grid>
  );
}

function DailyNewsWidgetContainer({ isoDate }: { isoDate: string }) {
  return (
    <Grid item xs={12} md={8}>
      <DailyNewsWidget isoDate={isoDate} />
    </Grid>
  );
}

function DailyLearningWidgetContainer({ isoDate }: { isoDate: string }) {
  return <DailyLearningWidget isoDate={isoDate} />;
}

function DailyImageWidgetContainer({ isoDate }: { isoDate: string }) {
  return (
    <Grid item xs={12} md={8}>
      <DailyImageWidget isoDate={isoDate} />
    </Grid>
  );
}
